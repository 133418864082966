import { inject } from '@angular/core';
import { HistoryLog, HistoryLogDetail } from '@iot-platform/models/audit-trail';
import { CommonApiResponse, Pagination } from '@iot-platform/models/common';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AbstractAuditTrailService } from '../../services/abstract-audit-trail.service';
import { AuditTrailActions } from '../actions/audit-trail.actions';

const loadHistoryLogs$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), auditTrailService = inject(AbstractAuditTrailService)) =>
    actions$.pipe(
      ofType(AuditTrailActions.loadHistoryLogs),
      switchMap(({ request }) =>
        auditTrailService.getAll(request).pipe(
          map((response: CommonApiResponse<HistoryLog, Pagination>) => AuditTrailActions.loadHistoryLogsSuccess({ response })),
          catchError((error) => of(AuditTrailActions.loadHistoryLogsFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const loadHistoryLogById$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), auditTrailService = inject(AbstractAuditTrailService)) =>
    actions$.pipe(
      ofType(AuditTrailActions.loadHistoryLogById),
      switchMap((action) =>
        auditTrailService.getById(action.params).pipe(
          map((response: HistoryLogDetail) => AuditTrailActions.loadHistoryLogByIdSuccess({ response })),
          catchError((error) => of(AuditTrailActions.loadHistoryLogByIdFailure({ error })))
        )
      )
    ),
  { functional: true }
);

const showLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(AuditTrailActions.loadHistoryLogs, AuditTrailActions.loadHistoryLogById),
      tap(() => notificationService.showLoader())
    ),
  { functional: true, dispatch: false }
);

const hideLoader$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(
        AuditTrailActions.loadHistoryLogsSuccess,
        AuditTrailActions.loadHistoryLogsFailure,
        AuditTrailActions.loadHistoryLogByIdSuccess,
        AuditTrailActions.loadHistoryLogByIdFailure
      ),
      tap(() => notificationService.hideLoader())
    ),
  { functional: true, dispatch: false }
);

const displayError$ = createEffect(
  /* istanbul ignore next */
  (actions$ = inject(Actions), notificationService = inject(NotificationService)) =>
    actions$.pipe(
      ofType(AuditTrailActions.loadHistoryLogsFailure, AuditTrailActions.loadHistoryLogByIdFailure),
      tap((action: Action) => notificationService.displayError(action.type))
    ),
  { functional: true, dispatch: false }
);

export const AuditTrailEffects = {
  loadHistoryLogs$,
  loadHistoryLogById$,
  showLoader$,
  hideLoader$,
  displayError$
};
