import { GetUtils } from '@iot-platform/iot-platform-utils';
import { HistoryLog, HistoryLogDetail } from '@iot-platform/models/audit-trail';
import { BaseState, Filter, INITIAL_BASE_STATE, Pagination } from '@iot-platform/models/common';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { AuditTrailActions } from '../actions/audit-trail.actions';

export interface State extends BaseState<HistoryLog, Pagination, Filter> {
  logsDetailsList: { [key: string]: HistoryLogDetail };
}

export const adapter: EntityAdapter<HistoryLog> = createEntityAdapter<HistoryLog>({
  selectId: (entity: HistoryLog) => entity.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  ...INITIAL_BASE_STATE,
  logsDetailsList: {},
  currentLogDetails: null,
  pagination: { currentPage: 0, hasMore: false, limit: 50, maxPage: 0, total: 0 }
});

export const auditTrailFeature = createFeature({
  name: 'auditTrail',
  reducer: createReducer(
    initialState,

    on(
      AuditTrailActions.setFilters,
      (state: State, { filters }): State => ({
        ...state,
        filters: [...filters]
      })
    ),

    on(
      AuditTrailActions.setCurrentHistoryLog,
      (state: State, { historyLog }): State => ({
        ...state,
        entity: historyLog
      })
    ),

    on(
      AuditTrailActions.loadHistoryLogs,
      (state: State, { request }): State => ({
        ...state,
        entity: null,
        filters: GetUtils.get(request, 'filters', []),
        logsDetailsList: {}
      })
    ),
    on(
      AuditTrailActions.loadHistoryLogsSuccess,
      (state: State, { response }): State =>
        adapter.setAll(GetUtils.get(response, 'data', []), {
          ...state,
          pagination: GetUtils.get(response, 'pagination')
        })
    ),

    on(
      AuditTrailActions.loadHistoryLogByIdSuccess,
      (state: State, { response }): State => ({
        ...state,
        logsDetailsList: {
          ...state.logsDetailsList,
          [response.id]: response
        }
      })
    ),

    on(
      AuditTrailActions.loadHistoryLogs,
      AuditTrailActions.loadHistoryLogById,
      (state: State): State => ({
        ...state,
        loading: true,
        loaded: false
      })
    ),
    on(
      AuditTrailActions.loadHistoryLogsSuccess,
      AuditTrailActions.loadHistoryLogByIdSuccess,
      (state: State): State => ({
        ...state,
        loading: false,
        loaded: true
      })
    ),
    on(
      AuditTrailActions.loadHistoryLogsFailure,
      AuditTrailActions.loadHistoryLogByIdFailure,
      (state: State, { error }): State => ({
        ...state,
        loading: false,
        loaded: false,
        error
      })
    )
  )
});
