import { NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { Component, effect, input, output, signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenuItem } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { ConditionProcessorUtil } from '@iot-platform/iot-platform-utils';

import { MasterViewEngineEvent } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'grid-engine-call-to-action-menu-item',
  templateUrl: './call-to-action-menu-item.component.html',
  imports: [FlexLayoutModule, MatDivider, UpperCasePipe, MatMenuItem, TranslateModule, MatIcon, NgTemplateOutlet, MatTooltip],
  styleUrls: ['./call-to-action-menu-item.component.scss']
})
export class CallToActionMenuItemComponent {
  element = input<any>();
  action = input<any>();
  userPermissions = input<{ key: string; value: boolean }[]>();

  actionClicked = output<MasterViewEngineEvent>();
  sendIsVisible = output<{ action: any; isVisible: boolean }>();

  isVisible: WritableSignal<boolean> = signal(false);
  isEnabled: WritableSignal<boolean> = signal(false);
  isDisabled: WritableSignal<boolean> = signal(false);

  constructor() {
    effect(
      () => {
        const element = this.element();
        const action = this.action();
        this.setVisibleAndEnable(element, action);
      },
      { allowSignalWrites: true }
    );
  }

  onSingleActionClick(actionKey: string): void {
    const element = this.element();
    this.actionClicked.emit({
      type: actionKey,
      options: { elementId: element?.id, actionKey, element },
      rawData: element
    });
  }

  private setVisibleAndEnable(element: any, action: any): void {
    const userPermissions = this.userPermissions();
    if (element && action) {
      const isVisible = ConditionProcessorUtil.processConditionsWithPermission(action.visibleConditions, userPermissions);
      this.isVisible.set(isVisible);
      this.isEnabled.set(ConditionProcessorUtil.processConditionsWithElement(action.enableConditions, element, true));
      this.isDisabled.set(ConditionProcessorUtil.processConditionsWithElement(action.disableConditions, element, false));
      this.sendIsVisible.emit({ action, isVisible });
    }
  }
}
