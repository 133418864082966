import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { NotificationService } from '@iot-platform/notification';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { catchError, exhaustMap, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { AuthorizationService } from '../../services/authorization.service';
import { AuthApiActions } from '../actions/auth-api.actions';
import { AuthBusinessProfilesApiActions } from '../actions/auth-business-profiles-api.actions';
import { AuthBusinessProfilesPageActions } from '../actions/auth-business-profiles-page.actions';
import { AuthPageActions } from '../actions/auth-page.actions';
import { AuthFacade } from '../facades/auth.facade';

@Injectable()
export class AuthEffects {
  private readonly actions$: Actions = inject(Actions);
  private readonly authService: AuthService = inject(AuthService);
  private readonly authFacade: AuthFacade = inject(AuthFacade);
  private readonly router: Router = inject(Router);
  private readonly authorizationsService: AuthorizationService = inject(AuthorizationService);
  private readonly notificationService: NotificationService = inject(NotificationService);
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly storage: LocalStorageService = inject(LocalStorageService);
  signInSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.signInSuccess),
      mergeMap((action) => [AuthApiActions.storeSession({ session: action.cognitoUser }), AuthPageActions.loadAccount()])
    )
  );
  selectBusinessProfileSuccessThenLoadPrivileges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthBusinessProfilesApiActions.selectBusinessProfileSuccess),
      map(() => AuthPageActions.loadPrivileges())
    )
  );
  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.signIn),
      mergeMap((action) =>
        this.authService.signIn(action.username, action.password).pipe(
          map((user: CognitoUser) => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              return AuthPageActions.requireNewPassword({
                username: user.getUsername()
              });
            } else {
              return AuthApiActions.signInSuccess({ cognitoUser: user });
            }
          }),
          catchError((error) => of(AuthApiActions.signInFailure({ error })))
        )
      )
    )
  );
  refreshSsoTokensSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.refreshSsoTokensSuccess),
      tap(({ idToken, accessToken, tokenType, expiresIn }) => {
        this.authService.storeSsoTokens({ idToken, accessToken, tokenType, expiresIn: String(expiresIn) });
      }),
      mergeMap(() => [AuthPageActions.loadAccount()])
    )
  );
  checkIfUserIsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.checkIfUserIsAdmin),
      switchMap((action) =>
        this.authService.isUserAdmin(action.entityId, action.userId).pipe(
          map((isUserAdmin) =>
            AuthApiActions.checkIfUserIsAdminSuccess({
              isAdminUser: isUserAdmin
            })
          ),
          catchError((error) => of(AuthApiActions.checkIfUserIsAdminFailure(error)))
        )
      )
    )
  );
  retrieveSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.retrieveSession),
      switchMap(() =>
        this.authService.retrieveCognitoUser().pipe(
          map((cognitoUser) =>
            AuthApiActions.retrieveSessionSuccess({
              cognitoUser
            })
          ),
          catchError((error) => of(AuthApiActions.retrieveSessionFailure({ error })))
        )
      )
    )
  );
  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.signOut),
      mergeMap(() =>
        this.authService.logout().pipe(
          map(() => AuthApiActions.signOutSuccess()),
          catchError((error) => of(AuthApiActions.signOutFailure({ error })))
        )
      )
    )
  );
  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.refreshToken),
      switchMap(() =>
        this.authService.retrieveCognitoUser().pipe(
          map((refreshed) => AuthApiActions.refreshTokenSuccess({ refreshed })),
          catchError((error) => of(AuthApiActions.refreshTokenFailure({ error })))
        )
      )
    )
  );
  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.changePassword),
      mergeMap((action) =>
        this.authService.changePassword(action.user, action.newPassword).pipe(
          map((result) => AuthApiActions.changePasswordSuccess({ result })),
          catchError((error) => of(AuthApiActions.changePasswordFailure({ error })))
        )
      )
    )
  );
  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.forgotPassword),
      mergeMap((action) =>
        this.authService.forgotPassword(action.username).pipe(
          map((result) => AuthApiActions.forgotPasswordSuccess({ result })),
          catchError((error) => of(AuthApiActions.forgotPasswordFailure({ error })))
        )
      )
    )
  );
  forgotPasswordSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.updatePasswordWithCode),
      mergeMap((action) =>
        this.authService
          .forgotPasswordSubmit({
            username: action.username,
            password: action.password,
            code: action.code
          })
          .pipe(
            map((result) => AuthApiActions.updatePasswordWithCodeSuccess({ result })),
            catchError((error) => of(AuthApiActions.updatePasswordWithCodeFailure({ error })))
          )
      )
    )
  );
  checkIfUserIsAdminAfterSelectBusinessProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthBusinessProfilesApiActions.selectBusinessProfileSuccess),
      concatLatestFrom(() => [this.authFacade.selectedBusinessProfile$, this.authFacade.userId$]),
      map(([, selectedBusinessProfile, currentUserId]) =>
        AuthApiActions.checkIfUserIsAdmin({
          entityId: selectedBusinessProfile?.entityId,
          userId: currentUserId
        })
      )
    )
  );
  checkIfUserIsAdminAfterLoadSelectedEntityForSessionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthBusinessProfilesApiActions.loadSelectedEntityForSessionSuccess, AuthApiActions.loadAccountSuccess),
      concatLatestFrom(() => [this.authFacade.selectedEntityForSession$, this.authFacade.userId$]),
      exhaustMap(([_, selectedEntityForSession, currentUserId]) =>
        of(
          AuthApiActions.checkIfUserIsAdmin({
            entityId: selectedEntityForSession?.id,
            userId: currentUserId
          })
        )
      )
    )
  );
  retrieveSessionFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.retrieveSessionFailure),
        tap(() => {
          this.router.navigate(['/login']);
        })
      ),
    { dispatch: false }
  );
  loadAuthorizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.loadPrivileges),
      switchMap(() =>
        this.authorizationsService.getAuthorizations().pipe(
          map((privileges) => AuthApiActions.loadPrivilegesSuccess({ privileges })),
          catchError((error) => of(AuthApiActions.loadPrivilegesFailure({ error })))
        )
      )
    )
  );
  validateSsoTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.validateSsoTokens),
      switchMap(({ idToken, accessToken, refreshToken, expiresIn }) =>
        this.authService.validateSsoTokens(idToken).pipe(
          map(() => AuthApiActions.signInWithSSOSuccess({ idToken, accessToken, refreshToken, expiresIn })),
          catchError((error: HttpErrorResponse) => {
            let errorMsg: string;
            switch (error.status) {
              case 401:
                errorMsg = 'Account not found please contact your key user';
                break;
              case 403:
                errorMsg = 'Your account is disabled please contact your key user';
                break;
              default:
                errorMsg = 'Authentication failed please contact your key user';
            }
            setTimeout(() => this.notificationService.displayError(errorMsg));

            return of(AuthApiActions.signInWithSSOFailure({ error }), AuthPageActions.signOut());
          })
        )
      )
    )
  );
  signOutFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.signOutFailure),
        tap(() => {
          this.notificationService.hideLoader();
        })
      ),
    { dispatch: false }
  );
  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.updatePasswordWithCodeSuccess),
        tap((action) => this.notificationService.displaySuccess(action.type))
      ),
    { dispatch: false }
  );
  retrieveSsoSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.retrieveSsoSession),
      mergeMap(({ idToken, accessToken, refreshToken }) => [
        AuthApiActions.retrieveSsoSessionSuccess({ idToken, accessToken, refreshToken }),
        AuthPageActions.loadAccount(),
        AuthBusinessProfilesPageActions.selectBusinessProfile({
          selectedBusinessProfile: JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)),
          withRedirect: false
        })
      ]),
      // eslint-disable-next-line rxjs/no-unsafe-catch
      catchError((error) => of(AuthApiActions.retrieveSsoSessionFailure({ error })))
    )
  );
  signInWithSSOSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.signInWithSSOSuccess),
      tap(() => {
        this.storage.set(LocalStorageKeys.STORAGE_SSO_LOGGED_IN_AT, `${Date.now()}`);
        this.router.navigate(['/login']);
      }),
      map(() => AuthPageActions.loadAccount())
    )
  );
  retrieveSessionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.retrieveSessionSuccess),
      mergeMap((action) => [
        AuthApiActions.storeSession({ session: action.cognitoUser }),
        AuthPageActions.loadAccount(),
        AuthBusinessProfilesPageActions.selectBusinessProfile({
          selectedBusinessProfile: JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_BUSINESS_PROFILE_KEY)),
          withRedirect: false
        })
      ])
    )
  );
  storeSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.storeSession),
        concatLatestFrom(() => this.authFacade.cognitoUser$),
        tap(([action, cognitoUser]) => {
          this.storage.set(LocalStorageKeys.STORAGE_ID_TOKEN_KEY, action.session.getSignInUserSession().getIdToken().getJwtToken());
          if (cognitoUser) {
            this.authService.storeSession(JSON.stringify(cognitoUser.getSignInUserSession().getIdToken().getJwtToken()));
          }
        })
      ),
    { dispatch: false }
  );
  loadAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthPageActions.loadAccount),
      switchMap(() =>
        this.authService.loadAccount().pipe(
          map((account) => {
            this.storage.set(LocalStorageKeys.STORAGE_USER_PREFERENCES, JSON.stringify(account.preferences));
            return AuthApiActions.loadAccountSuccess({ account });
          }),
          catchError((error) => of(AuthApiActions.loadAccountFailure({ error })))
        )
      )
    )
  );
  signOutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthApiActions.signOutSuccess),
        tap(() => {
          this.storage.clear();
          this.dialog.closeAll();
          this.router.navigate(['/login']);
          this.notificationService.hideLoader();
        })
      ),
    { dispatch: false }
  );
}
