import { inject, Injectable } from '@angular/core';
import { AbstractMasterViewService, FeatureMasterViewEvent } from '@iot-platform/feature/master-view';
import { IotToolbarDefaultButton } from '@iot-platform/iot-platform-ui';
import { HistoryLog } from '@iot-platform/models/audit-trail';
import { FavoriteView, Filter, MasterViewEngineEvent } from '@iot-platform/models/common';
import { GridNavigationType, I4BGrid, I4BGridData, I4BGridOptions } from '@iot-platform/models/grid-engine';
import { noop, Observable, of } from 'rxjs';
import { AuditTrailFacade } from '../+state/facades/audit-trail.facade';
import { AuditTrailGrid } from '../grids/audit-trail-grid-definition';
import { auditTrailAdditionalOptions } from '../settings/audit-trail.provider';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailMasterViewService extends AbstractMasterViewService {
  private readonly auditTrailFacade: AuditTrailFacade = inject(AuditTrailFacade);

  getPermissions(): Observable<
    {
      key: string;
      value: boolean;
    }[]
  > {
    return of([]);
  }

  getInitialFilters(): Observable<Filter[]> {
    return of([]);
  }

  getStaticGridDefinition(): Observable<I4BGrid<I4BGridOptions, I4BGridData> | null> {
    return of(new AuditTrailGrid());
  }

  onMasterViewEngineEvent(mvEvent: FeatureMasterViewEvent): void {
    const event = mvEvent.event as MasterViewEngineEvent;
    switch (`${event.type}`) {
      case 'rowClicked':
      case `${GridNavigationType.KEY_UP}`:
      case `${GridNavigationType.KEY_DOWN}`: {
        if (event.data) {
          this.loadLogDetails(event.data);
        }
        break;
      }
    }
  }

  onToolbarEvent() {
    noop();
  }

  getGridConfiguration(): Observable<{
    sortedGridsWithoutAppDefault: I4BGrid<I4BGridOptions, I4BGridData>[];
    currentGrid: I4BGrid<I4BGridOptions, I4BGridData> | undefined;
    isGridsLoading: boolean;
  } | null> {
    return of(null);
  }

  getGrids(): Observable<I4BGrid<I4BGridOptions, I4BGridData>[]> {
    return of([]);
  }

  getToolbarButtons(): Observable<IotToolbarDefaultButton[]> {
    return of([]);
  }

  getFavoriteViewsConfiguration(): Observable<{
    sortedFavoriteViews: FavoriteView[];
    currentFavoriteView: FavoriteView | undefined;
    isFavoriteViewsLoading: boolean;
  } | null> {
    return of(null);
  }

  canUpdateBusinessProfile(): Observable<boolean> {
    return of(false);
  }

  onBusinessProfileChange() {
    noop();
  }

  private loadLogDetails(log: HistoryLog) {
    this.auditTrailFacade.setCurrentHistoryLog(log);
    const currentLogDetails = this.auditTrailFacade.currentLogDetails();
    if (currentLogDetails?.id !== log?.id) {
      this.auditTrailFacade.loadHistoryLogById({
        ...log,
        additionalOptions: auditTrailAdditionalOptions()
      });
    }
  }
}
