import { Route } from '@angular/router';
import { AuthGuard, LoginCallbackComponent, LoginShellComponent } from '@iot-platform/auth';
import { provideDaliaApplicationState } from '@iot-platform/dalia/data-access';
import { NavigationRoutes } from '@iot-platform/models/common';

export const APP_ROUTES: Route[] = [
  {
    path: NavigationRoutes.LOGIN,
    children: [
      {
        path: '',
        component: LoginShellComponent
      },
      {
        path: NavigationRoutes.LOGIN_CALLBACK,
        component: LoginCallbackComponent
      }
    ],
    providers: [provideDaliaApplicationState()]
  },
  {
    path: '',
    loadChildren: () => import('@iot-platform/dalia/feature/app-shell').then((m) => m.AppShellRoutes),
    canActivate: [AuthGuard],
    providers: [provideDaliaApplicationState()]
  },
  { path: NavigationRoutes.HOME, redirectTo: `/${NavigationRoutes.DEVICES}` },
  { path: '', redirectTo: `/${NavigationRoutes.HOME}`, pathMatch: 'full' },
  { path: '**', redirectTo: `/${NavigationRoutes.HOME}` }
];
