export const IPV4_MASK = {
  mask: [/\d/, /\d?/, /\d?/, '.', /\d/, /\d?/, /\d?/, '.', /\d/, /\d?/, /\d?/, '.', /\d/, /\d?/, /\d?/],
  guide: false,
  placeholderChar: '_',
  pipe: (conformedValue) => {
    conformedValue = conformedValue?.replaceAll('..', '.');
    conformedValue = conformedValue?.replaceAll('_', '');
    return conformedValue;
  },
  keepCharPositions: true,
  showMask: false
};
