<form [formGroup]="form" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
  @if (data()?.autocomplete) {
    @if (data()?.multiSelect) {
      <iot-platform-ui-async-autocomplete-multiple-selects
        (selectionChanged)="onSelectionChange($event)"
        [clearOnSelect]="true"
        [currentFilters]="currentFilters()"
        [data]="dynamicList()"
        [displayKey]="data()?.selectByProperty"
        [displaySearchIcon]="false"
        [displayWrapper]="data()?.displayWrapper"
        [filterKey]="data()?.skipAutoCompleteFilterCriteria ? null : data()?.selectByProperty"
        [filterBy]="data()?.filterBy"
        [minLength]="1"
        [multiSelect]="data()?.multiSelect"
        [placeholder]="data()?.placeholder"
        [showSpinner]="showLoader()"
        [disabled]="disabled()"
        [tooltip]="data()?.tooltip"
        [disableOnlyOptions]="true"
      />
    } @else {
      <iot-platform-ui-async-autocomplete
        [clearOnSelect]="true"
        (selectionChanged)="onSelectionChange($event)"
        [data]="dynamicList()"
        [displayKey]="data()?.selectByProperty"
        [displaySearchIcon]="false"
        [displayWrapper]="data()?.displayWrapper"
        [filterKey]="data()?.skipAutoCompleteFilterCriteria ? null : data()?.selectByProperty"
        [filterBy]="data()?.filterBy"
        [minLength]="1"
        [placeholder]="data()?.placeholder"
        [showSpinner]="showLoader()"
        [tooltip]="data()?.tooltip"
        [disabled]="disabled()"
      />
    }
  } @else {
    <div>
      <mat-form-field class="select-field" color="accent">
        <mat-label fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
          <span class="filter-label" fxFlex="90">{{ data()?.placeholder ?? data()?.criteriaLabel | translate }}</span>
          @if (showLoader()) {
            <mat-progress-spinner [diameter]="16" color="accent" fxFlex="10" mode="indeterminate" />
          }
        </mat-label>

        @if (data()?.multiSelect) {
          <mat-select
            (selectionChange)="onSelectionChange($event?.value)"
            multiple
            data-cy="iot-platform-ui-dynamic-list-field-single-select"
            formControlName="select"
          >
            @if (!showLoader()) {
              @for (option of dynamicList(); track $index) {
                <mat-option [disabled]="!isSelectedOption(option) && disabled()" [value]="option"
                            data-cy="iot-platform-ui-dynamic-list-field-single-select-option"
                >{{ getOptionToDisplay(option) }}
                </mat-option>
              }
            }
          </mat-select>
        } @else {
          <mat-select
            (selectionChange)="onSelectionChange($event?.value)"
            data-cy="iot-platform-ui-dynamic-list-field-single-select"
            formControlName="select"
          >
            @if (!showLoader()) {
              @for (option of dynamicList(); track $index) {
                <mat-option [disabled]="!isSelectedOption(option) && disabled()" [value]="option"
                            data-cy="iot-platform-ui-dynamic-list-field-single-select-option"
                >{{ getOptionToDisplay(option) }}
                </mat-option>
              }
            }
          </mat-select>
        }
      </mat-form-field>
    </div>
  }
</form>
