import { HistoryLog, HistoryLogDetail } from '@iot-platform/models/audit-trail';
import { CommonApiRequest, CommonApiResponse, Filter, Pagination } from '@iot-platform/models/common';
import { createActionGroup, props } from '@ngrx/store';

export const AuditTrailActions = createActionGroup({
  source: 'Audit Trail',
  events: {
    'Load History Logs': props<{
      request: CommonApiRequest;
    }>(),
    'Load History Logs Success': props<{
      response: CommonApiResponse<HistoryLog, Pagination>;
    }>(),
    'Load History Logs Failure': props<{
      error: unknown;
    }>(),

    'Load History Log By Id': props<{
      params: { id: string } & { [key: string]: unknown };
    }>(),
    'Load History Log By Id Success': props<{
      response: HistoryLogDetail;
    }>(),
    'Load History Log By Id Failure': props<{
      error: unknown;
    }>(),

    'Set Filters': props<{
      filters: Filter[];
    }>(),

    'Set Current History Log': props<{
      historyLog: HistoryLog;
    }>()
  }
});
