import { NgClass } from '@angular/common';
import { Component, computed, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { SortUtil } from '@iot-platform/iot-platform-utils';
import { Filter, FilterMultipleInputsOptions } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractFilterEngineFieldComponent } from '../../abstract-filter-engine-field.component';

@Component({
  standalone: true,
  imports: [FlexLayoutModule, TranslateModule, ReactiveFormsModule, MatFormFieldModule, NgClass, MatButtonModule, MatInput, MatIcon],
  selector: 'iot-platform-ui-multiple-inputs-field',
  templateUrl: './multiple-inputs-field.component.html',
  styleUrls: ['./multiple-inputs-field.component.scss']
})
export class MultipleInputsFieldComponent extends AbstractFilterEngineFieldComponent<FilterMultipleInputsOptions> implements OnInit {
  form: UntypedFormGroup = new UntypedFormGroup({});
  inputs = computed(() => {
    const data = this.data();
    return data?.inputs?.sort(SortUtil.sortByOrder);
  });

  ngOnInit() {
    this.initForm();
  }

  onInputValidation(inputKey: string) {
    if (this.form.get(inputKey).value) {
      const filter: Filter = {
        criteriaKey: inputKey,
        criteriaLabel: this.data().inputs.find((i) => i.criteriaKey === inputKey).criteriaLabel,
        value: this.form.get(inputKey).value.trim(),
        label: this.form.get(inputKey).value.trim()
      };

      this.dispatchFilterEvent(filter);
      this.form.get(inputKey).reset();
    }
  }

  private initForm(): void {
    const group = {};
    this.data().inputs.forEach((input) => {
      group[input.criteriaKey] = new UntypedFormControl(null);
    });
    this.form = new UntypedFormGroup(group);
  }
}
