import { inject, Injectable, Signal } from '@angular/core';
import { CommonApiRequest, Filter } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions, I4BGridSort } from '@iot-platform/models/grid-engine';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GridsDbActions } from '../actions';
import * as fromGridEngine from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class GridEngineFacade {
  private readonly store: Store = inject(Store);

  selectedGridByMasterView: Signal<I4BGrid<I4BGridOptions, I4BGridData>> = this.store.selectSignal(fromGridEngine.selectSelectedGridByMasterView);
  gridDataLoadedByGridId: Signal<boolean> = this.store.selectSignal(fromGridEngine.selectGridDataLoadedByGridId);
  gridSortByGridId: Signal<I4BGridSort[]> = this.store.selectSignal(fromGridEngine.selectGridSortByGridId);
  selectedMasterView: Signal<string> = this.store.selectSignal(fromGridEngine.selectSelectedMasterView);

  getDefaultGrid(masterview: string) {
    return this.store.select(fromGridEngine.selectDefaultGridByMasterview(masterview));
  }

  getTotalDefaultGrid(masterview: string): Observable<number> {
    return this.store.select(fromGridEngine.selectTotalByGrid(masterview));
  }

  getLoadedDefaultGrid(masterview: string): Observable<boolean> {
    return this.store.select(fromGridEngine.selectDataLoadedByGrid(masterview));
  }

  getSortDefaultGrid(masterview: string): Observable<I4BGridSort[]> {
    return this.store.select(fromGridEngine.selectSortByGrid(masterview));
  }

  selectGridAndLoadData(gridId: string, masterview: string, filters: Filter[] = [], endPoint: string = ''): void {
    this.store.dispatch(GridsDbActions.selectGridAndLoadData({ gridId, masterview, filters, endPoint }));
  }

  reloadGridData(request: CommonApiRequest): void {
    this.store.dispatch(GridsDbActions.loadGridData({ request }));
  }

  selectCurrentMaterView(masterview: string): void {
    this.store.dispatch(GridsDbActions.selectCurrentMaterView({ masterview }));
  }
}
