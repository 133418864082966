import { Injectable, Signal } from '@angular/core';
import { HistoryLog, HistoryLogDetail } from '@iot-platform/models/audit-trail';
import { BaseFacade, CommonApiRequest, Filter, Pagination } from '@iot-platform/models/common';
import { Store } from '@ngrx/store';
import { AuditTrailActions } from '../actions/audit-trail.actions';
import { AuditTrailSelectors } from '../selectors/audit-trail.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailFacade extends BaseFacade<HistoryLog, Pagination, Filter> {
  currentLogDetails: Signal<HistoryLogDetail> = this.store.selectSignal(this.selector.selectCurrentLogDetail);

  constructor(
    protected override store: Store,
    protected override selector: AuditTrailSelectors
  ) {
    super(store, selector);
  }

  getAll(request: CommonApiRequest) {
    this.store.dispatch(AuditTrailActions.loadHistoryLogs({ request }));
  }

  setFilters(filters: Filter[]) {
    this.store.dispatch(AuditTrailActions.setFilters({ filters }));
  }

  loadHistoryLogById(params: { id: string } & { [key: string]: unknown }): void {
    this.store.dispatch(AuditTrailActions.loadHistoryLogById({ params }));
  }

  setCurrentHistoryLog(historyLog: HistoryLog): void {
    this.store.dispatch(AuditTrailActions.setCurrentHistoryLog({ historyLog }));
  }
}
