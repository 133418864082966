import { WidgetAction } from './widget-action.enum';
import { WidgetSize } from './widget-size.enum';

export interface BaseWidgetOptions {
  size?: WidgetSize;
  menu?: Partial<{
    enabled?: boolean;
    actions: Partial<{
      name: WidgetAction;
      label: string;
      enabled: boolean;
    }>[];
  }>;
  header?: Partial<{
    enabled: boolean;
    title: string;
  }>;
  footer?: Partial<{
    enabled: boolean;
    legend: string;
  }>;
}
