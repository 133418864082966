import { Injectable } from '@angular/core';
import { HistoryLog, HistoryLogDetail } from '@iot-platform/models/audit-trail';
import { ApiFeatureSelector, Filter, Pagination } from '@iot-platform/models/common';
import { createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import { get } from 'lodash';
import * as fromAuditTrail from '../reducers/audit-trail.reducer';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailSelectors extends ApiFeatureSelector<fromAuditTrail.State, HistoryLog, Pagination, Filter> {
  selectTotal: MemoizedSelector<fromAuditTrail.State, number> = createSelector(this.selectState, (state: fromAuditTrail.State): number =>
    get(state, ['pagination', 'total'], 0)
  );

  selectLogsDetailsList: MemoizedSelector<fromAuditTrail.State, { [key: string]: HistoryLogDetail }> = createSelector(
    this.selectState,
    (state: fromAuditTrail.State) => state.logsDetailsList
  );

  selectCurrentLogDetail: MemoizedSelector<fromAuditTrail.State, HistoryLogDetail> = createSelector(
    this.selectState,
    this.selectCurrentEntity,
    (state: fromAuditTrail.State, entity: HistoryLog) => get(state, ['logsDetailsList', entity?.id])
  );

  constructor() {
    super(fromAuditTrail.auditTrailFeature.selectAuditTrailState, fromAuditTrail.adapter);
  }
}
