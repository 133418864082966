import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CustomEncoder } from '@iot-platform/core';
import { SortEntitiesUtil } from '@iot-platform/iot-platform-utils';

import { CommonApiListResponse, Entity, Environment } from '@iot-platform/models/common';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EntitiesService {
  constructor(
    @Inject('environment') private environment: Environment,
    public httpClient: HttpClient
  ) {}

  getAll(): Observable<Entity[]> {
    return this.httpClient.get<Entity[]>(this.environment.api.url + this.environment.api.endpoints.entities).pipe(map((entities: any) => entities.content));
  }

  getHierarchicallySortedEntities(): Observable<Entity[]> {
    return this.getAll().pipe(map((entities: any) => SortEntitiesUtil.sortHierarchically(entities)));
  }

  isNameUnique(entityName: string): Observable<boolean> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('limit', '0');
    params = params.set('page', '0');
    params = params.set('name', entityName);
    return this.httpClient
      .get<CommonApiListResponse<Entity>>(`${this.environment.api.url}${this.environment.api.endpoints.entities}`, { params })
      .pipe(map((data) => data.page.total === 0));
  }
}
