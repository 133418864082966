import { ChangeDetectionStrategy, Component, effect, forwardRef, inject, input, output, signal, Signal, WritableSignal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@bluehalo/ngx-leaflet-markercluster';
import { AnalyticsService, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { Filter } from '@iot-platform/models/common';
import { Asset, Device, Site } from '@iot-platform/models/i4b';
import { DateFormatPipe, NumberFormatPipe } from '@iot-platform/pipes';
import * as Leaflet from 'leaflet';
import { Layer } from 'leaflet';
import 'leaflet-control-geocoder';
import 'leaflet.markercluster';
import { cloneDeep } from 'lodash';
import { MapLayersHelper } from '../../helpers/map-layers.helper';
import { MapMarkersHelper } from '../../helpers/map-markers.helper';
import { IotGeoJsonFeature, IotMapActionType, IotMapDisplayMode } from '../../models';
import { MapNavigationEvent } from '../../models/iot-map-navigation-event.model';
import { MapPopupService } from '../../services/map-popup.service';
import { MapFacade } from '../../state/facades/map.facade';
import { MapPanelInfoComponent } from '../map-panel-info/map-panel-info.component';
import { MapSpinnerComponent } from '../map-spinner/map-spinner.component';
import { SimpleMapComponent } from '../simple-map/simple-map.component';

Leaflet.Icon.Default.imagePath = 'assets/map';

@Component({
  standalone: true,
  imports: [
    FlexLayoutModule,
    MapSpinnerComponent,
    MatSidenavModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    forwardRef(() => MapPanelInfoComponent),
    DateFormatPipe,
    NumberFormatPipe
  ],
  providers: [DateFormatPipe, NumberFormatPipe, MapPopupService],
  selector: 'iot-platform-maps-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent extends SimpleMapComponent {
  private readonly mapFacade: MapFacade = inject(MapFacade);
  private readonly popupService: MapPopupService = inject(MapPopupService);
  private readonly storage: LocalStorageService = inject(LocalStorageService);

  filters = input<Filter[]>([]);

  analytic: AnalyticsService = new AnalyticsService('map');
  dispatchMapNavigationEvent = output<MapNavigationEvent<Site | Asset | Device>>();
  assetVariable: any;

  displayPanelInfo: WritableSignal<boolean> = signal(false);
  selectedFeature: WritableSignal<IotGeoJsonFeature | undefined> = signal(undefined);
  refresh: Signal<boolean> = this.mapFacade.refresh;
  selectedLayers: WritableSignal<{ [concept: string]: string }> = signal(
    this.storage.get(LocalStorageKeys.STORAGE_MAP_LAYERS_KEY)
      ? JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_MAP_LAYERS_KEY))
      : { sites: 'Basic', assets: 'Basic', devices: 'CCF' }
  );
  conceptEffect = effect(
    () => {
      const concept = this.concept();
      if (concept) {
        this.mapFacade.setConcept(concept);
        this.applicablePopup.set(this.popupService.getPopup(this.concept(), this.displayMode()));
      }
    },
    { injector: this.injector, allowSignalWrites: true }
  );
  filtersEffect = effect(
    () => {
      const filters = this.filters();
      if (filters) {
        this.cleanLayers();
        this.mapFacade.clearRoutes();
        this.displayPanelInfo.set(false);
        this.loadData();
      }
    },
    { injector: this.injector, allowSignalWrites: true }
  );
  refreshEffect = effect(
    () => {
      const refresh = this.refresh();
      if (refresh) {
        this.loadData();
      }
    },
    { injector: this.injector, allowSignalWrites: true }
  );
  selectedLayersEffect = effect(
    () => {
      const selectedLayers = this.selectedLayers();
      this.storage.set(LocalStorageKeys.STORAGE_MAP_LAYERS_KEY, JSON.stringify(selectedLayers));
      const currentDisplayMode: IotMapDisplayMode = MapLayersHelper.getDisplayModeByLayer(this.concept(), selectedLayers[this.concept()]);
      this.applicablePopup.set(this.popupService.getPopup(this.concept(), currentDisplayMode));
      if (this.selectedMarker) {
        this.selectedMarker.setIcon(MapMarkersHelper.getMarkerIcon(this.selectedMarker.feature as IotGeoJsonFeature, this.displayMode()));
        this.selectedMarker = null;
      }
      this.displayPanelInfo.set(false);
    },
    { injector: this.injector, allowSignalWrites: true }
  );

  constructor() {
    super();
    effect(
      () => {
        const loading = this.mapFacade.maploading();
        this.loading.set(loading);
      },
      { allowSignalWrites: true }
    );
  }

  override initApplicablePopupEffect() {
    effect(
      () => {
        const popup = this.popup();
        if (popup) {
          this.applicablePopup.set(popup);
        }
        this.applicablePopup.set(this.popupService.getPopup(this.concept(), this.displayMode()));
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  override onMapReady(map: Leaflet.Map): void {
    this.map = map;
    this.dispatchEvent.emit({
      type: IotMapActionType.MAP_READY,
      map,
      popup: this.applicablePopup() ? this.applicablePopup() : this.popupService.getPopup(this.concept(), this.displayMode())
    });
    this.initFeatures();
    this.initRoutes();
    this.manageAdditionalBaseLayers();
    this.map.invalidateSize();
  }

  loadData(): void {
    const selectedLayerConfig = MapLayersHelper.getSelectedLayerConfig(this.concept(), this.selectedLayers()[this.concept()]);
    if (selectedLayerConfig) {
      const additionalFilter: Filter | undefined = selectedLayerConfig.additionalFilter;
      this.mapFacade.getAll({
        concept: this.concept(),
        displayMode: selectedLayerConfig.displayMode,
        filters: additionalFilter ? [...this.filters(), additionalFilter] : this.filters()
      });
    } else {
      this.mapFacade.getAll({
        concept: this.concept(),
        displayMode: this.displayMode(),
        filters: this.filters()
      });
    }
  }

  initRoutes(): void {
    effect(
      () => {
        this.mapFacade.routesLoaded();
        this.mapFacade.routesLoading();
        this.mapFacade.hasRoutes();
        this.mapFacade.currentRoutes();
        this.cleanLayers();
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  override initFeatures(): void {
    effect(
      () => {
        const currentFeatures = this.mapFacade.currentFeatures();
        this.features.set([...currentFeatures]);
        if (currentFeatures.length === 0) {
          const defaultPosition = this.defaultPosition();
          const zoom = this.zoom();
          this.map.setZoom(zoom);
          this.map.panTo(defaultPosition);
        }
        this.initMarkers();
      },
      { injector: this.injector, allowSignalWrites: true }
    );

    if (!this.features().length) {
      this.loadData();
    }
  }

  generateRoutePoint(feature: IotGeoJsonFeature): Leaflet.Marker {
    const marker: Leaflet.Marker = Leaflet.marker(Leaflet.latLng([feature.geometry.coordinates[1], feature.geometry.coordinates[0]] as Leaflet.LatLngTuple), {
      draggable: false,
      icon: Leaflet.divIcon({
        html: '<svg style="width: 3px; height: 3px;"><circle r="3" cx="3" cy="3" stroke-width="1" fill="#bf6660"></circle><svg>',
        iconSize: [0, 0],
        iconAnchor: [3, 3]
      })
    });
    marker.feature = feature;

    return marker;
  }

  override markerHovered(event: Leaflet.LeafletMouseEvent, feature: IotGeoJsonFeature): void {
    this.analytic.log('map-actions', 'marker-hovered');
    super.markerHovered(event, feature);
  }

  override markerClicked($event: Leaflet.LeafletMouseEvent, feature: IotGeoJsonFeature): void {
    this.analytic.log('map-actions', 'marker-clicked');
    this.cleanLayers();
    this.mapFacade.clearRoutes();
    this.dispatchEvent.emit({
      type: IotMapActionType.MARKER_CLICK,
      marker: $event.target,
      feature
    });
    this.mapFacade.saveMapUiState($event.target);
    if (!this.displayPanelInfo()) {
      if (this.selectedMarker?.feature?.properties.id !== $event.target.feature.properties.id) {
        this.selectedMarker = $event.target;
        this.selectedMarker?.setIcon(MapMarkersHelper.getMarkerIconActive(feature, this.displayMode()));
      } else {
        $event.target.setIcon(MapMarkersHelper.getMarkerIcon(feature, this.displayMode()));
        this.selectedMarker?.setIcon(MapMarkersHelper.getMarkerIconActive(feature, this.displayMode()));
      }
      this.displayPanelInfo.set(true);
    } else {
      this.selectedMarker?.setIcon(MapMarkersHelper.getMarkerIcon(this.selectedMarker.feature as IotGeoJsonFeature, this.displayMode()));
      this.selectedMarker = cloneDeep($event.target);
      this.selectedMarker?.setIcon(MapMarkersHelper.getMarkerIconActive(feature, this.displayMode()));
    }
    this.selectedFeature.set(feature);
  }

  onElementSelection(event: MapNavigationEvent<Site | Asset | Device>) {
    this.dispatchMapNavigationEvent.emit(event);
  }

  onDisplaySegments(event: { layers: Layer[]; action: 'add' | 'remove' }) {
    if (event.action === 'add') {
      event.layers
        .sort((a: Leaflet.Layer, b: Leaflet.Layer) => (a > b ? 1 : -1))
        .forEach((layer) => {
          this.map.addLayer(layer);
        });
    } else {
      event.layers.forEach((layer) => {
        if (this.map.hasLayer(layer)) {
          this.map.removeLayer(layer);
        }
      });
    }

    const bounds: any[] = [];
    this.map.eachLayer((layer: Leaflet.Layer) => {
      if (layer instanceof Leaflet.GeoJSON) {
        bounds.push(layer.getBounds());
      }
    });

    if (bounds.length > 0) {
      this.map.fitBounds(bounds);
    }
  }

  onClosePanelInfo() {
    this.analytic.log('map-actions', 'close-panel-info');
    this.displayPanelInfo.set(false);
    this.cleanLayers();
    if (this.selectedMarker) {
      this.selectedMarker.setIcon(MapMarkersHelper.getMarkerIcon(this.selectedMarker.feature as IotGeoJsonFeature, this.displayMode()));
      this.selectedMarker = null;
    }
  }

  onBaseLayerChange = (e: Leaflet.LayersControlEvent) => {
    const selectedLayerConfig = MapLayersHelper.getSelectedLayerConfig(this.concept(), e.name);

    if (selectedLayerConfig) {
      this.displayMode.set(selectedLayerConfig.displayMode);
      const additionalFilter: Filter | undefined = selectedLayerConfig.additionalFilter;
      this.mapFacade.getAll({
        concept: this.concept(),
        displayMode: selectedLayerConfig.displayMode,
        filters: additionalFilter ? [...this.filters(), additionalFilter] : this.filters()
      });
      this.cleanLayers();
      this.mapFacade.clearRoutes();
      this.dispatchEvent.emit({
        type: IotMapActionType.CHANGE_DISPLAY_MODE,
        displayMode: this.displayMode()
      });
      this.selectedLayers.set({ ...this.selectedLayers(), [this.concept()]: e.name });
      this.analytic.log('map-actions', 'selected-layer-changed', `[${this.concept}] : ${e.name}`);
    }
  };

  private manageAdditionalBaseLayers() {
    const layerControlForCurrentConcept: {
      [layerName: string]: Leaflet.LayerGroup;
    } = MapLayersHelper.getLayersByConcept(this.concept());
    const layerControl = Leaflet.control.layers(layerControlForCurrentConcept, {}, this.layersControlOptions);
    layerControl.addTo(this.map);
    const activeLayer: string = this.selectedLayers()[this.concept()];
    if (activeLayer) {
      layerControlForCurrentConcept[activeLayer].addTo(this.map);
    }
    this.map.on('baselayerchange', this.onBaseLayerChange);
  }
}
