import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, inject, model, OnInit, output, signal, Signal } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { IotToolbarEvent } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { StatusButtonComponent } from '../../status-button/status-button.component';
import { IotToolbarButtonConfig } from '../models/button.model';
import { IotToolbarDispatchActionType } from './configuration-files/button-dispatch-action-type.enum';
import { IotToolbarButtonType } from './configuration-files/button-type.enum';

@Component({
  standalone: true,
  imports: [
    MatButtonModule,
    TranslateModule,
    MatTooltipModule,
    MatIconModule,
    MatMenuModule,
    FlexLayoutModule,
    StatusButtonComponent,
    MatDividerModule,
    NgClass,
    NgTemplateOutlet
  ],
  selector: 'iot-platform-ui-toolbar-button',
  templateUrl: './toolbar-button.component.html',
  styleUrls: ['./toolbar-button.component.scss']
})
export class ToolbarButtonComponent implements OnInit {
  private readonly storage: LocalStorageService = inject(LocalStorageService);

  button = model.required<Partial<IotToolbarButtonConfig>>();
  buttonClick = output<IotToolbarEvent>();

  IotToolbarButtonType = IotToolbarButtonType;
  filterEngineOpenByDefault: Signal<boolean> = signal(
    JSON.parse(this.storage.get(LocalStorageKeys.STORAGE_USER_PREFERENCES))?.filterEngineOpenByDefault ?? false
  );
  computedClasses: Signal<{ [key: string]: boolean }> = computed(() => {
    const button = this.button();
    if (button.type === IotToolbarButtonType.TOGGLE_BUTTON) {
      return {
        [button.toggleOnClass as string]: button.isToggleOn as boolean,
        [button.toggleOffClass as string]: !button.isToggleOn
      };
    }
    return {};
  });

  ngOnInit() {
    this.initFilterEngineButtonStatus();
  }

  initFilterEngineButtonStatus(): void {
    const button = this.button();
    const filterEngineOpenByDefault = this.filterEngineOpenByDefault();
    if (button.dispatchAction?.type === IotToolbarDispatchActionType.TOGGLE_FILTER_ENGINE && filterEngineOpenByDefault) {
      this.button.update((value) => ({ ...value, isToggleOn: true }));
    }
  }

  onButtonClick(event: IotToolbarEvent): void {
    const button = this.button();
    if (button.type === IotToolbarButtonType.TOGGLE_BUTTON && button.dispatchAction?.type !== IotToolbarDispatchActionType.SUBSCRIPTION) {
      this.button.update((value) => ({ ...value, isToggleOn: !value.isToggleOn }));
    }
    this.buttonClick.emit(event);
  }

  onMenuOptionClick(event: IotToolbarEvent): void {
    this.buttonClick.emit(event);
  }
}
