<div
  [class]="cssClassName()"
  [style]="styles()"
  class="iot-platform-ui-section-header"
  data-cy="iot-platform-ui-section-header"
  fxLayout="row wrap"
  fxLayoutAlign="start center"
  fxLayoutGap="10px"
>
  <div [matTooltip]="title()" class="iot-platform-ui-section-header__title-section" fxLayoutAlign="start center" fxLayoutGap="10px" matTooltipClass="regular-tooltip"  matTooltipPosition="below">
    @if (!!svgIcon()) {
      <mat-icon data-cy="iot-platform-ui-section-header-icon" class="iot-platform-ui-section-header__icon" [svgIcon]="svgIcon()!"></mat-icon>
    } @else if (!!icon()) {
      <mat-icon data-cy="iot-platform-ui-section-header-icon" class="iot-platform-ui-section-header__icon">{{ icon() }}</mat-icon>
    }
    @if (title()) {
      <span data-cy="iot-platform-ui-section-header-title" class="iot-platform-ui-section-header__title iot-platform-ui-section-header__title--ellipsis">{{ title() }}</span>
    }
  </div>

  @if(showLine()) {
    <hr class="iot-platform-ui-section-header__line regular-1px-line" fxFlex />
  }

  <div fxLayout="row wrap" fxLayoutGap="10px">
    @for (action of actions(); track action) {

      @if (!!action?.visible) {
        @if(action?.visible?.()) {
          <ng-container [ngTemplateOutlet]="sectionHeaderAction"
                        [ngTemplateOutletContext]="{ action: action }" />
        }
      } @else {
        <ng-container [ngTemplateOutlet]="sectionHeaderAction"
                      [ngTemplateOutletContext]="{ action: action }" />
      }
    }
  </div>
</div>

<ng-template #sectionHeaderAction let-action="action">
  <button
    (click)="onDispatchEvent(action)"
    [class]="
          action?.cssClassName?.() +
          ' light-button regular-round-button iot-platform-ui-section-header__action iot-platform-ui-section-header__action_' +
          action.action
        "
    [disabled]="!!action?.disabled?.()"
    [matTooltip]="action?.tooltip?.()! | translate"
    [style]="action?.styles?.()"
    data-cy="iot-platform-ui-section-header-action"
    mat-mini-fab
    matTooltipClass="regular-tooltip"
    matTooltipPosition="below"
  >
    @if (!!action?.svgIcon?.()) {
      <mat-icon class="iot-platform-ui-section-header__action-icon" [svgIcon]="action.svgIcon()!"></mat-icon>
    } @else if (!!action?.icon?.()) {
      <mat-icon class="iot-platform-ui-section-header__action-icon">{{ action.icon() }}</mat-icon>
    }
  </button>
</ng-template>
