import { HistoryLog } from '@iot-platform/models/audit-trail';
import { CommonApiResponse, CommonIndexedPagination } from '@iot-platform/models/common';
import {
  ArrayOfObjectColumn,
  HeaderType,
  I4BBasicColumn,
  I4BButtonColumn,
  I4bCellConcept,
  I4BCellType,
  I4BColumn,
  I4BColumnConfiguration,
  I4BColumnHeader,
  I4BColumnOptions,
  I4BGrid,
  I4BGridData,
  I4BGridFilterType,
  I4BGridOptions,
  I4BSelectionColumn
} from '@iot-platform/models/grid-engine';

export class AuditTrailGridData implements I4BGridData {
  response!: CommonApiResponse<HistoryLog, CommonIndexedPagination>;
}

export const AUDIT_TRAIL_MASTER_VIEW_NAME = 'audit-trail';

export class AuditTrailGrid implements I4BGrid<I4BGridOptions, AuditTrailGridData> {
  masterview = AUDIT_TRAIL_MASTER_VIEW_NAME;
  isDefault = false;
  isUserDefault = false;
  isAppDefault = false;
  name = AUDIT_TRAIL_MASTER_VIEW_NAME;
  userId: string | null = null;
  data: AuditTrailGridData = {
    response: {
      data: [],
      pagination: null
    }
  };
  gridOptions: I4BGridOptions = {
    pageSize: 3000,
    multipleSelection: false,
    autoRefresh: { enabled: false },
    gridSort: [{ colId: 'datetime', sort: 'desc', sortIndex: 1 }],
    buttonColumn: {
      enabled: false,
      className: new I4BButtonColumn(
        {
          type: HeaderType.CALL_TO_ACTION,
          displayName: 'BUTTON',
          icon: ''
        },
        {
          concept: I4bCellConcept.AUDIT_TRAIL,
          isConfigurable: false,
          isDefault: true,
          isLink: false,
          position: '',
          sortProperty: '',
          width: 10,
          id: 'button',
          cell: {
            type: I4BCellType.CALL_TO_ACTION,
            bulkActions: [],
            singleActions: [],
            disableConditions: [],
            visibleConditions: [],
            enableConditions: []
          }
        },
        { order: 1000 }
      )
    },
    selectionColumn: {
      enabled: false,
      className: new I4BSelectionColumn(undefined)
    }
  };
  columns: I4BColumn<I4BColumnHeader, I4BColumnConfiguration, I4BColumnOptions>[] = [
    new ArrayOfObjectColumn(
      { displayName: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ATTRIBUTES' },
      {
        id: 'updatedAttributes',
        translationKey: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DEVICE_FILES',
        isDefault: false,
        sortProperty: 'updatedAttributes',
        width: 300,
        filterParams: {
          enabled: true,
          filterType: I4BGridFilterType.TEXT_COLUMN_FILTER
        }
      },
      { order: 1 }
    ),
    new I4BBasicColumn(
      { displayName: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.ACTION' },
      {
        id: 'conceptEvent',
        isDefault: true,
        isConfigurable: true,
        sortProperty: 'conceptEvent',
        autoSize: true,
        filterParams: {
          enabled: true,
          filterType: I4BGridFilterType.SET_COLUMN_FILTER
        }
      },
      { order: 2 }
    ),
    new I4BBasicColumn(
      { displayName: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.USER' },
      {
        id: 'context.userId',
        isDefault: true,
        sortProperty: 'context.userId',
        cell: {
          type: I4BCellType.USER_CELL,
          options: {
            defaultValue: 'AUDIT_TRAIL.HISTORY_LOG_POPUP.SYSTEM'
          }
        },
        autoSize: true,
        filterParams: {
          enabled: true,
          filterType: I4BGridFilterType.USER_COLUMN_TEXT_FILTER
        }
      },
      { order: 3 }
    ),
    new I4BBasicColumn(
      { displayName: 'MASTER_VIEW_ENGINE.TABLE.HEADER_NAME.DATE_TIME' },
      {
        id: 'datetime',
        isDefault: true,
        sortProperty: 'datetime',
        autoSize: true,
        cell: { type: I4BCellType.DATE },
        filterParams: {
          enabled: true,
          filterType: I4BGridFilterType.DATE_COLUMN_FILTER
        }
      },
      { order: 4 }
    )
  ];
}
