import { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';
import { Component, computed } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormControlType, FormGroupComponent } from '@iot-platform/iot-platform-ui';
import { I4BGridFilterOptions } from '@iot-platform/models/grid-engine';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';
import { AbstractCellFilterComponent } from '../abstract-filter.component';

@Component({
  standalone: true,
  selector: 'grid-engine-rich-variable-value-cell-filter',
  imports: [FormsModule, MatFormFieldModule, MatSelectModule, MatInputModule, TranslateModule, FlexModule, MatButtonModule, FormGroupComponent],
  templateUrl: './rich-variable-value-cell-filter.component.html'
})
export class RichVariableValueCellFilterComponent extends AbstractCellFilterComponent {
  constructor() {
    super();
    this.fields = computed(() => [
      this.operatorField,
      {
        ...this.searchTermField,
        type: FormControlType.NUMBER
      }
    ]);
  }

  override agInit(params: IFilterParams & { filterOptions: I4BGridFilterOptions[] }): void {
    super.agInit(params);
    this.operators.set([
      { label: I4BGridFilterOptions.EQUALS, value: '===' },
      { label: I4BGridFilterOptions.LESS_THAN, value: '<' },
      { label: I4BGridFilterOptions.GREATER_THAN, value: '>' },
      { label: I4BGridFilterOptions.LESS_THAN_OR_EQUAL, value: '<=' },
      { label: I4BGridFilterOptions.GREATER_THAN_OR_EQUAL, value: '>=' }
    ]);
  }

  handleDoesFilterPass(params: IDoesFilterPassParams): boolean {
    const field = get(this.filterParams, ['colDef', 'filterParams', 'field'], get(this.filterParams, ['colDef', 'field']));
    const value = get(params.data, field, '').toString().toLowerCase();
    // eslint-disable-next-line no-eval
    return eval(`${value}${this.operatorControl?.getRawValue()?.value}${this.lastValue}`);
  }
}
