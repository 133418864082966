import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  Injector,
  input,
  model,
  OnDestroy,
  output,
  Signal,
  signal,
  untracked,
  WritableSignal
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatDrawer, MatDrawerContainer, MatDrawerContent } from '@angular/material/sidenav';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@bluehalo/ngx-leaflet-markercluster';
import { DynamicDataResponse } from '@iot-platform/models/common';
import { DateFormatPipe, NumberFormatPipe } from '@iot-platform/pipes';
import { TranslateService } from '@ngx-translate/core';
import * as Leaflet from 'leaflet';
import { PopupOptions } from 'leaflet';
import 'leaflet-control-geocoder';
import 'leaflet.markercluster';
import { cloneDeep, debounce, get } from 'lodash';
import { MapClustersHelper } from '../../helpers/map-clusters.helper';
import { MapMarkersHelper } from '../../helpers/map-markers.helper';
import {
  IotGeoJsonFeature,
  IotGeoJsonRouteFeature,
  IotMapActionType,
  IotMapDisplayMode,
  IotMapDisplayType,
  IotMapEvent,
  IotMapMarkerPopup,
  IotMapMarkerPopupRawData
} from '../../models';
import { MapPopupService } from '../../services/map-popup.service';
import { MapPanelInfoComponent } from '../map-panel-info/map-panel-info.component';
import { MapSpinnerComponent } from '../map-spinner/map-spinner.component';
import LayersOptions = Leaflet.Control.LayersOptions;

Leaflet.Icon.Default.imagePath = 'assets/map';
const MAX_ZOOM = 18;
const MIN_ZOOM = 2;

@Component({
  selector: 'iot-platform-maps-simple-map',
  standalone: true,
  imports: [
    LeafletModule,
    LeafletMarkerClusterModule,
    FlexModule,
    MapPanelInfoComponent,
    MapSpinnerComponent,
    MatDrawer,
    MatDrawerContainer,
    MatDrawerContent,
    DateFormatPipe,
    NumberFormatPipe
  ],
  providers: [DateFormatPipe, NumberFormatPipe, MapPopupService],
  templateUrl: './simple-map.component.html',
  styleUrl: './simple-map.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleMapComponent implements OnDestroy {
  concept = input<string>('sites');
  defaultCoordinates = input<[number, number]>([48, 2.5]);
  displayMode = model<IotMapDisplayMode>('Basic');
  displayType = input<IotMapDisplayType>(IotMapDisplayType.CLUSTER);
  displayChunks = input<boolean>(false); //  Display chunks of dataset only in the displayed area of the map, This can resolve performance issue displayType = IotMapDisplayType.POINT
  zoom = input<number>(3);
  features = model<IotGeoJsonFeature[]>([]);
  loading = model<boolean>(false);
  popup = input<IotMapMarkerPopup>();
  dispatchEvent = output<IotMapEvent>();
  map!: Leaflet.Map;
  routes: IotGeoJsonRouteFeature[] = [];
  selectedMarker!: Leaflet.Marker | null;
  layersControlOptions: LayersOptions = { position: 'topleft', collapsed: false };
  markers: WritableSignal<Leaflet.Marker[]> = signal([]);
  clusterOptions: Leaflet.MarkerClusterGroupOptions = {
    iconCreateFunction: (cluster: Leaflet.MarkerCluster) => MapClustersHelper.createClusterIcon(cluster, this.concept(), this.displayMode()),
    spiderfyDistanceMultiplier: 1.1,
    maxClusterRadius: 70
  };
  baseLayers: {
    [name: string]: Leaflet.Layer;
  } = {
    OpenStreetMap: Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: MAX_ZOOM,
      attribution: undefined,
      minZoom: MIN_ZOOM
    }) as Leaflet.Layer
  };
  applicablePopup: WritableSignal<IotMapMarkerPopup | null> = signal(null);
  defaultPosition: Signal<Leaflet.LatLng> = computed(() => {
    const coordinates = this.defaultCoordinates();
    return Leaflet.latLng(coordinates as Leaflet.LatLngTuple);
  });
  options: Leaflet.MapOptions = {
    maxZoom: MAX_ZOOM,
    minZoom: MIN_ZOOM,
    center: this.defaultPosition(),
    zoomControl: true,
    attributionControl: false
  };
  protected readonly translateService: TranslateService = inject(TranslateService);
  popupLoadingContent = `<b class='leaflet-popup-content__section'>${this.translateService.instant('CARD_LOADER.LOADING')}</b>`;
  protected readonly injector: Injector = inject(Injector);
  markersEffect = effect(
    () => {
      const markers = this.markers();
      untracked(() => {
        this.removeMarkers();
        const feature = new Leaflet.MarkerClusterGroup(this.clusterOptions);
        feature.addLayers(markers);
        if (this.map && feature.getBounds().isValid()) {
          this.map.addLayer(feature);
          this.map.fitBounds(feature.getBounds());
        }
        this.map.setZoom(this.zoom());
      });
    },
    { injector: this.injector, allowSignalWrites: true }
  );
  defaultCoordinatesEffect = effect(
    () => {
      const defaultPosition = this.defaultPosition();
      if (defaultPosition) {
        this.options.center = defaultPosition;
      }
    },
    { injector: this.injector, allowSignalWrites: true }
  );

  constructor() {
    this.initApplicablePopupEffect();
  }

  initApplicablePopupEffect() {
    effect(
      () => {
        const popup = this.popup();
        if (popup) {
          this.applicablePopup.set(popup);
        }
        this.applicablePopup.set(new IotMapMarkerPopup().addTemplateRow(null, (rawData: IotMapMarkerPopupRawData) => get(rawData, 'feature.properties.name')));
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  onMapReady(map: Leaflet.Map): void {
    this.map = map;
    this.dispatchEvent.emit({
      type: IotMapActionType.MAP_READY,
      map,
      popup: this.applicablePopup()
    });
    this.initFeatures();
    this.onMapMoveEnd();
    this.map.invalidateSize();
  }

  onMapMoveEnd(): void {
    this.map.on(
      'moveend',
      debounce(() => {
        this.dispatchEvent.emit({
          type: IotMapActionType.MAP_MOVE_END,
          map: this.map
        });
        if (this.displayChunks()) {
          this.removeMarkers();
          this.initMarkers();
        }
      }, 200)
    );
  }

  cleanLayers(): void {
    if (this.map) {
      this.map.eachLayer((layer: Leaflet.Layer) => {
        if (layer instanceof Leaflet.GeoJSON) {
          this.map.removeLayer(layer);
        }
      });
    }
  }

  initFeatures(): void {
    effect(
      () => {
        const features = this.features();
        const defaultPosition = this.defaultPosition();
        const zoom = this.zoom();
        if (features.length === 0) {
          this.map.setZoom(zoom);
          this.map.panTo(defaultPosition);
        }
        this.initMarkers();
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  initMarkers(): void {
    const markers: Leaflet.Marker[] = [];
    this.features().forEach((feature: IotGeoJsonFeature | IotGeoJsonRouteFeature) => {
      if (this.displayType() === IotMapDisplayType.CLUSTER || this.displayType() === IotMapDisplayType.POINT) {
        const marker: Leaflet.Marker = this.generateMarker(feature as IotGeoJsonFeature);
        if (this.hasValidCoordinates(marker) && this.isMarkerInMapBound(marker)) {
          markers.push(marker);
        }
        if (this.hasValidCoordinates(marker) && this.displayType() === IotMapDisplayType.POINT) {
          this.map.addLayer(marker);
        }
      }
    });
    if (this.displayType() === IotMapDisplayType.CLUSTER) {
      this.markers.set([...markers]);
    }
  }

  getPopup(data: DynamicDataResponse, feature: IotGeoJsonFeature): IotMapMarkerPopup {
    const popup: IotMapMarkerPopup = new IotMapMarkerPopup({ ...this.applicablePopup()?.options, data, feature });
    popup.templateRows = this.applicablePopup()?.templateRows as never;
    return popup.build();
  }

  generateMarker(feature: IotGeoJsonFeature): Leaflet.Marker {
    const marker: Leaflet.Marker = Leaflet.marker(Leaflet.latLng([feature.geometry.coordinates[1], feature.geometry.coordinates[0]] as Leaflet.LatLngTuple), {
      draggable: false,
      icon: MapMarkersHelper.getMarkerIcon(feature, this.displayMode())
    });
    marker.feature = feature;
    if (get(this.applicablePopup(), 'displayPopup')) {
      const popupOptions: PopupOptions = {
        autoClose: true,
        closeButton: false,
        offset: Leaflet.point(-160, -25)
      };
      marker.bindPopup(get(this.applicablePopup(), 'loadData') ? this.popupLoadingContent : this.getPopup(null, feature), popupOptions);
    }
    marker.on('click', (event: Leaflet.LeafletMouseEvent) => this.markerClicked(event, feature));
    marker.on('mouseover', (event: Leaflet.LeafletMouseEvent) => this.markerHovered(event, feature));
    marker.on('mouseout', (event: Leaflet.LeafletMouseEvent) => this.markerLeaved(event, feature));
    return marker;
  }

  markerHovered(event: Leaflet.LeafletMouseEvent, feature: IotGeoJsonFeature): void {
    event.target.openPopup();
    if (this.selectedMarker?.feature?.properties?.id !== event?.target?.feature?.properties?.id) {
      event.target.setIcon(MapMarkersHelper.getMarkerIconHover(feature, this.displayMode()));
    }
  }

  markerLeaved(event: Leaflet.LeafletMouseEvent, feature: IotGeoJsonFeature): void {
    event.target.closePopup();
    if (this.selectedMarker?.feature?.properties?.id !== event?.target?.feature?.properties?.id) {
      event.target.setIcon(MapMarkersHelper.getMarkerIcon(feature, this.displayMode()));
    }
  }

  markerClicked($event: Leaflet.LeafletMouseEvent, feature: IotGeoJsonFeature): void {
    this.cleanLayers();
    this.dispatchEvent.emit({
      type: IotMapActionType.MARKER_CLICK,
      marker: $event.target,
      feature
    });
    this.selectedMarker?.setIcon(MapMarkersHelper.getMarkerIcon(this.selectedMarker.feature as IotGeoJsonFeature, this.displayMode()));
    this.selectedMarker = cloneDeep($event.target);
    this.selectedMarker?.setIcon(MapMarkersHelper.getMarkerIconActive(feature, this.displayMode()));
  }

  hasValidCoordinates(marker: Leaflet.Marker): boolean {
    const latLong: Leaflet.LatLng = marker.getLatLng();
    return Math.abs(latLong.lat) <= 90 && Math.abs(latLong.lng) <= 180;
  }

  isMarkerInMapBound(marker: Leaflet.Marker): boolean {
    return this.displayChunks() ? this.map.getBounds().contains(marker.getLatLng()) : true;
  }

  removeMarkers(): void {
    if (this.map) {
      this.map.eachLayer((layer: Leaflet.Layer) => {
        if (layer instanceof Leaflet.Marker || layer instanceof Leaflet.MarkerCluster || layer instanceof Leaflet.MarkerClusterGroup) {
          this.map.removeLayer(layer);
        }
      });
    }
  }

  initCurrentPosition(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          this.map.panTo(Leaflet.latLng([position.coords.latitude, position.coords.longitude]));
        },
        () => {
          this.map.panTo(this.defaultPosition());
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.cleanLayers();
  }
}
