import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SortEntitiesUtil, SortUtil } from '@iot-platform/iot-platform-utils';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DynamicListFieldService {
  private readonly httpClient: HttpClient = inject(HttpClient);

  getDynamicList(
    url: string,
    sortMethod: {
      type: string;
      property?: string;
    },
    arrayOrObject = 'object'
  ): Observable<any[]> {
    return this.httpClient.get(url).pipe(
      map((data: any) => {
        if (data.content) {
          return this.sortDynamicList(data.content, sortMethod, arrayOrObject);
        } else {
          return this.sortDynamicList(data, sortMethod, arrayOrObject);
        }
      })
    );
  }

  sortDynamicList(data: any[], sortMethod: { type: string; property?: string }, arrayOrObject?: string): any[] {
    switch (sortMethod.type) {
      case 'alphabetically':
      case 'asc':
      case 'desc': {
        if (arrayOrObject === 'object') {
          return data.sort(SortUtil.sortBy(get(sortMethod, ['property'], 'name'), sortMethod.type !== 'desc'));
        }
        return data.sort();
      }
      case 'entityHierarchically':
        return SortEntitiesUtil.sortHierarchically(data);
      case '':
        return data.sort();
      default:
        return data;
    }
  }
}
