import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { NgClass } from '@angular/common';
import { Component, inject, signal, WritableSignal } from '@angular/core';
import { CachedUsersService } from '@iot-platform/core';
import { UserNamePipe } from '@iot-platform/pipes';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { CustomCellParams } from '../../../models/custom-cell.params';

@Component({
  selector: 'grid-engine-user-cell',
  standalone: true,
  templateUrl: './user-cell.component.html',
  imports: [UserNamePipe, TranslateModule, NgClass],
  providers: [UserNamePipe],
  styleUrls: ['./user-cell.component.scss']
})
export class UserCellComponent implements ICellRendererAngularComp {
  params: WritableSignal<CustomCellParams> = signal(null);
  value = signal(null);
  protected readonly cachedUsersService: CachedUsersService = inject(CachedUsersService);
  protected readonly translateService: TranslateService = inject(TranslateService);
  protected readonly userNamePipe: UserNamePipe = inject(UserNamePipe);

  agInit(params: CustomCellParams & { isFilterRenderer?: boolean }): void {
    params.colDef.filterParams.users = this.cachedUsersService.cachedUsers$.getValue();
    this.params.set(params);
    if (!params.value) {
      const value = params.isFilterRenderer
        ? get(params, ['colDef', 'cellRendererParams', 'cellOptions', 'defaultValue'], 'AG_GRID.blanks')
        : get(params, ['cellOptions', 'defaultValue'], params.value);
      this.value.set(this.translateService.instant(value));
    } else if (params.value === this.translateService.instant('AG_GRID.selectAll')) {
      this.value.set(params.value);
    } else {
      this.value.set(this.userNamePipe.transform(params.value));
    }
  }

  refresh(): boolean {
    return false;
  }
}
