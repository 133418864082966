import { EnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { AuditTrailEffects } from '../effects/audit-trail.effects';
import { auditTrailFeature } from './audit-trail.reducer';

export function provideAuditTrailState(): EnvironmentProviders[] {
  // return [importProvidersFrom(StoreModule.forFeature(auditTrailFeature.name, auditTrailFeature.reducer), EffectsModule.forFeature([AuditTrailEffects]))];
  return [provideState(auditTrailFeature.name, auditTrailFeature.reducer), provideEffects([AuditTrailEffects])];
}
