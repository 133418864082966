import { DeviceTemplate as DaliaDeviceTemplate, Sensor as DaliaSensor } from '@iot-platform/models/dalia';
import { Calendar } from '@iot-platform/models/ocm';
import { XmqttDevice } from '@iot-platform/models/xmqtt';
import {
  BaseUser,
  CommonApiResponse,
  CommonGenericModel,
  CommonIndexedPagination,
  CommonPagination,
  Connector,
  EmailTemplate,
  Firmware
} from '../../../common/';
import { Asset, AssetEvent, AssetTemplate, Device as I4BDevice, DeviceEvent, PoEventRule, Site } from '../../../i4b';
import { DeviceDetails as KercomDeviceDetails } from '../../../kercom/device-details.model';

export interface I4BGridData {
  response: CommonApiResponse<CommonGenericModel, CommonPagination>;
}

export class SitesGridData implements I4BGridData {
  response!: CommonApiResponse<Site, CommonIndexedPagination>;
}

export class AssetsGridData implements I4BGridData {
  response!: CommonApiResponse<Asset, CommonIndexedPagination>;
}

export class DevicesGridData implements I4BGridData {
  response!: CommonApiResponse<I4BDevice, CommonIndexedPagination>;
}

export class KercomDevicesGridData implements I4BGridData {
  response!: CommonApiResponse<KercomDeviceDetails, CommonIndexedPagination>;
}

export class XmqttDevicesGridData implements I4BGridData {
  response!: CommonApiResponse<XmqttDevice, CommonIndexedPagination>;
}

export class FirmwaresGridData implements I4BGridData {
  response!: CommonApiResponse<Firmware, CommonIndexedPagination>;
}

export class DaliaDeviceTemplatesGridData implements I4BGridData {
  response!: CommonApiResponse<DaliaDeviceTemplate, CommonIndexedPagination>;
}

export class DaliaSensorsGridData implements I4BGridData {
  response!: CommonApiResponse<DaliaSensor, CommonIndexedPagination>;
}

export class DeviceEventsGridData implements I4BGridData {
  response!: CommonApiResponse<DeviceEvent, CommonIndexedPagination>;
}

export class AssetEventsGridData implements I4BGridData {
  response!: CommonApiResponse<AssetEvent, CommonIndexedPagination>;
}

export class EmailTemplatesGridData implements I4BGridData {
  response!: CommonApiResponse<EmailTemplate, CommonIndexedPagination>;
}

export class ConnectorsGridData implements I4BGridData {
  response!: CommonApiResponse<Connector, CommonIndexedPagination>;
}

export class PoEventRulesGridData implements I4BGridData {
  response!: CommonApiResponse<PoEventRule, CommonIndexedPagination>;
}

export class AssetTemplatesGridData implements I4BGridData {
  response!: CommonApiResponse<AssetTemplate, CommonIndexedPagination>;
}

export class UsersGridData implements I4BGridData {
  response!: CommonApiResponse<BaseUser, CommonIndexedPagination>;
}

export class CalendarsGridData implements I4BGridData {
  response!: CommonApiResponse<Calendar, CommonIndexedPagination>;
}
