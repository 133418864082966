import { get } from 'lodash';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class FlattenUtils {
  static flattenDeep = (element: any, key: string, predicate: (params: any) => boolean = () => true): any[] => {
    let flatArray: any[] = [];
    const flattenData = (data) => {
      if (predicate(data)) {
        flatArray.push(data);
        if (get(data, key)) {
          flatArray = flatArray.concat(this.flattenDeep(get(data, key), key, predicate));
        }
      }
    };
    if (element instanceof Array) {
      element.forEach((item) => {
        flattenData(item);
      });
    } else {
      flattenData(element);
    }
    return flatArray;
  };

  static flattenObjectDeep = (obj, roots = [], sep = '.') =>
    Object
      // find props of given object
      .keys(obj)
      // return an object by iterating props
      .reduce(
        (memo, prop) =>
          Object.assign(
            // create a new object
            {},
            // include previously returned object
            memo,
            Object.prototype.toString.call(obj[prop]) === '[object Object]'
              ? // keep working if value is an object
                this.flattenObjectDeep(obj[prop], roots.concat([prop]), sep)
              : // include current prop and value and prefix prop with the roots
                { [roots.concat([prop]).join(sep)]: obj[prop] }
          ),
        {}
      );
}
