export enum IotToolbarDispatchActionType {
  ADD_ELEMENT = 'ADD_ELEMENT',
  APPLY_DASHBOARD = 'APPLY_DASHBOARD',
  APPLY_FAVORITE_VIEW = 'APPLY_FAVORITE_VIEW',
  APPLY_GRID = 'APPLY_GRID',
  CANCEL = 'CANCEL',
  CLEAR_FILTERS_AND_RELOAD_DATA = 'CLEAR_FILTERS_AND_RELOAD_DATA',
  CONFIGURE_SCHEDULER = 'CONFIGURE_SCHEDULER',
  CREATE_FAVORITE_VIEW = 'CREATE_FAVORITE_VIEW',
  EDIT_FAVORITE_VIEW = 'EDIT_FAVORITE_VIEW',
  DELETE_ELEMENT = 'DELETE_ELEMENT',
  DELETE_FAVORITE_VIEW = 'DELETE_FAVORITE_VIEW',
  EDIT_ELEMENT = 'EDIT_ELEMENT',
  EXPORT_DATA = 'EXPORT_DATA',
  FILTER_TEXT_CHANGE = 'FILTER_TEXT_CHANGE',
  LAUNCH_NOW = 'LAUNCH_NOW',
  MANAGE_GRID_SETTINGS = 'MANAGE_GRID_SETTINGS',
  OPEN_GRID_ADMIN_SETTINGS = 'OPEN_GRID_ADMIN_SETTINGS',
  OPEN_HISTORY = 'OPEN_HISTORY',
  OPEN_MANAGE_PROTOCOLS = 'OPEN_MANAGE_PROTOCOLS',
  OPEN_MANAGE_SUBSCRIBERS = 'OPEN_MANAGE_SUBSCRIBERS',
  OPEN_MASTER_VIEW_ADMIN_SETTINGS = 'OPEN_MASTER_VIEW_ADMIN_SETTINGS',
  REFRESH_PAGE = 'REFRESH',
  SAVE = 'SAVE',
  SUBSCRIPTION = 'SUBSCRIPTION',
  TOGGLE_CATALOGS = 'TOGGLE_CATALOGS',
  TOGGLE_DEVICES_SELECTION_FOR_SCHEDULER = 'TOGGLE_DEVICES_SELECTION_FOR_SCHEDULER',
  TOGGLE_FILTER_ENGINE = 'TOGGLE_FILTER_ENGINE',
  TOGGLE_PAGE_TYPE = 'TOGGLE_PAGE_TYPE',
  PENDING = 'PENDING',
  PUBLISH = 'PUBLISH',
  CREATE = 'CREATE',
  CREATE_FROM_TEMPLATE = 'CREATE_FROM_TEMPLATE',
  CREATE_ASSET_GROUP = 'CREATE_ASSET_GROUP'
}
