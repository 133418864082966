<div class="map" fxFlexFill fxLayout="column" fxLayoutAlign="start center">
  <iot-platform-maps-map-spinner [loading]="loading()" />
  <div
    (leafletMapReady)="onMapReady($event)"
    [leafletBaseLayers]="baseLayers"
    [leafletOptions]="options"
    class="map__container"
    fxFlex="auto"
    leaflet
  ></div>
</div>
