import { RowSelectedEvent, SortChangedEvent } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  Injector,
  input,
  OnDestroy,
  OnInit,
  output,
  Signal,
  untracked
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { CommonApiRequest, Filter, Pagination } from '@iot-platform/models/common';
import { I4BGrid, I4BGridData, I4BGridOptions, I4BGridSort } from '@iot-platform/models/grid-engine';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GridsDbActions } from '../../state/actions';
import * as fromGrids from '../../state/reducers';
import { GridPageComponent } from '../grid-page/grid-page.component';

@Component({
  standalone: true,
  selector: 'grid-engine-grid-manager',
  templateUrl: './grid-manager.component.html',
  imports: [GridPageComponent],
  styleUrls: ['./grid-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridManagerComponent implements OnInit, OnDestroy {
  private readonly startTimer$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly destroyTimer$: Subject<void> = new Subject<void>();
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly store: Store = inject(Store);
  private readonly injector: Injector = inject(Injector);

  grid = input<I4BGrid<I4BGridOptions, I4BGridData>>();
  gridSort = input<I4BGridSort[]>([]);
  currentFilters = input<Filter[]>([]);
  userPermissions = input();
  visibleNodeId = input<string>();
  hidePaginator = input<boolean>(false);

  dispatchMasterViewEngineEvent = output<any>();
  dispatchGridEvent = output<any>();
  timerValueChanged = output<number>();
  pageChange = output<Pagination>();
  refreshActivated: Signal<boolean> = this.store.selectSignal(fromGrids.selectRefreshActivated);

  autoRefreshDelay: Signal<number> = computed(() => {
    const grid = this.grid();
    return get(grid, ['gridOptions', 'autoRefresh', 'delay']) || 0;
  });

  autoRefreshEnabled: Signal<boolean> = computed(() => {
    const grid = this.grid();
    return !!get(grid, ['gridOptions', 'autoRefresh', 'enabled'], false);
  });

  timer: Signal<number> = toSignal(this.getTimer().pipe(takeUntilDestroyed(this.destroyRef)));

  ngOnInit(): void {
    effect(
      () => {
        const counter = this.timer();
        untracked(() => {
          const autoRefreshDelay = this.autoRefreshDelay();
          if (counter >= autoRefreshDelay) {
            this.loadData();
            this.startTimer$.next(true);
          }
        });
      },
      { injector: this.injector, allowSignalWrites: true }
    );

    effect(
      () => {
        const grid = this.grid();
        const autoRefreshEnabled = this.autoRefreshEnabled();
        if (grid && autoRefreshEnabled) {
          this.startTimer$.next(true);
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );

    effect(
      () => {
        const refreshActivated = this.refreshActivated();
        if (refreshActivated) {
          this.startTimer$.next(true);
        }
      },
      { injector: this.injector, allowSignalWrites: true }
    );
  }

  ngOnDestroy(): void {
    this.destroyTimer();
  }

  loadData(): void {
    const grid = this.grid();
    const filters = this.currentFilters();
    const request: CommonApiRequest = {
      limit: get(grid, ['data', 'response', 'pagination', 'limit'], get(grid, ['gridOptions', 'pageSize'], 0)),
      page: get(grid, ['data', 'response', 'pagination', 'currentPage'], 0),
      filters,
      concept: grid.masterview.toLowerCase(),
      variables: grid.gridOptions.variableNames,
      tags: grid.gridOptions.tagIds,
      endPoint: grid.gridOptions.endPoint
    };
    this.store.dispatch(GridsDbActions.loadGridData({ request }));
  }

  loadGridDetails(grid: I4BGrid<I4BGridOptions, I4BGridData>) {
    this.store.dispatch(GridsDbActions.loadGridDetails({ concept: grid.masterview, gridId: grid.id }));
  }

  onPageChange(pagination: Pagination): void {
    const filters = this.currentFilters();
    const grid = this.grid();
    const request: CommonApiRequest = {
      limit: pagination.limit,
      page: pagination.currentPage,
      filters,
      concept: grid.masterview.toLowerCase(),
      variables: grid.gridOptions.variableNames,
      tags: grid.gridOptions.tagIds,
      endPoint: grid.gridOptions.endPoint
    };
    this.store.dispatch(GridsDbActions.changeGridPage({ request }));
    this.pageChange.emit(pagination);
  }

  onSelectRow(event: RowSelectedEvent) {
    this.store.dispatch(GridsDbActions.selectItemInGridData({ gridId: this.grid().id, itemId: event.data.id }));
  }

  onSortChange(sortEvent: { event: SortChangedEvent; grid: I4BGrid<I4BGridOptions, I4BGridData> }) {
    if (sortEvent?.grid?.id) {
      const newSort: I4BGridSort[] = sortEvent.event.api.getColumnState().map(({ colId, sort, sortIndex }) => ({
        colId,
        sort,
        sortIndex
      }));
      this.store.dispatch(GridsDbActions.sortGridData({ gridId: sortEvent.grid.id, gridSort: newSort }));
    }
  }

  onDispatchGridEvent(dispatched) {
    const grid = this.grid();
    const canEditGrid = !grid?.isAppDefault && !grid?.businessProfileId && grid?.userId;
    const column = dispatched?.event?.column ?? get(dispatched?.event, 'columns[0]');
    const resizeEnded = dispatched?.event?.type === 'columnResized' && dispatched?.event?.source === 'uiColumnResized' && column && dispatched?.event?.finished;
    if (canEditGrid && resizeEnded) {
      const cols = [...grid.columns];
      const idx = cols.findIndex((col) => col.configuration.id === column.getColId());
      if (idx !== -1) {
        const newCols = [...grid.columns];
        newCols[idx] = {
          ...newCols[idx],
          options: { ...newCols[idx].options, width: column.actualWidth }
        };
        const updateGrid: I4BGrid<I4BGridOptions, I4BGridData> = {
          ...grid,
          columns: newCols,
          data: null,
          gridOptions: { ...grid.gridOptions, filters: [] }
        };
        this.store.dispatch(GridsDbActions.updateSilentGrid({ toUpdate: updateGrid }));
      }
    }
    this.dispatchGridEvent.emit(dispatched);
  }

  private getTimer(): Observable<number> {
    return this.startTimer$.pipe(
      tap(() => {
        this.destroyTimer();
      }),
      filter((enabled: boolean) => enabled && this.autoRefreshDelay() > 0),
      switchMap(() => timer(0, 1 * 1000).pipe(takeUntil(this.destroyTimer$))),
      filter(() => this.refreshActivated() && this.autoRefreshEnabled()),
      tap((counter) => this.timerValueChanged.emit(counter))
    );
  }

  private destroyTimer(): void {
    this.destroyTimer$.next();
    this.destroyTimer$.complete();
  }
}
