@if (isVisible()) {
  @if (action()?.divider) {
    <mat-divider></mat-divider>
  }
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button class="grid-engine-call-to-action__menu-item" data-cy="grid-engine-call-to-action-menu-item" mat-menu-item [disabled]="!isEnabled() || isDisabled()" (click)="onSingleActionClick(action().key)">
      {{ action()?.label | translate | uppercase }}
    </button>

    <ng-container [ngTemplateOutlet]="tmplValueMeta" [ngTemplateOutletContext]="{ action: action(), data: element() }" />
  </div>
}

<ng-template #tmplValueMeta let-action="action" let-data="data">
  @if (action?.valueMeta?.icon) {
    @if (!!action?.valueMeta?.visibleExpression) {
      @if (action?.valueMeta?.visibleExpression?.(data)) {
        <mat-icon [class]="'grid-engine-call-to-action__value-meta-icon grid-engine-call-to-action__value-meta-icon--' + action?.label" [matTooltip]="action?.valueMeta?.tooltip | translate" matTooltipClass="regular-tooltip"
        >{{ action?.valueMeta?.icon }}
        </mat-icon>
      }
    } @else {
      <mat-icon [class]="'grid-engine-call-to-action__value-meta-icon grid-engine-call-to-action__value-meta-icon--' + action?.label" [matTooltip]="action?.valueMeta?.tooltip | translate" matTooltipClass="regular-tooltip"
      >{{ action?.valueMeta?.icon }}
      </mat-icon>
    }
  }
</ng-template>
