import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@iot-platform/core';
import { Environment } from '@iot-platform/models/common';
import { I4BBulkOperationApiResponse, I4BBulkOperationApiResponseStatus } from '@iot-platform/models/i4b';
import { chunk } from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  protected readonly environment: Environment = inject(ENVIRONMENT);
  protected readonly httpClient: HttpClient = inject(HttpClient);

  static getComputedStatus(
    chunkResponseStatus: I4BBulkOperationApiResponseStatus,
    currentStatus?: I4BBulkOperationApiResponseStatus
  ): I4BBulkOperationApiResponseStatus {
    if (!currentStatus || chunkResponseStatus === currentStatus) {
      return chunkResponseStatus;
    } else {
      return 'partial_success';
    }
  }

  bulkUpdateStatusByEventType(eventType: string, eventsIds: string[], status: string, maxLength: number = 200): Observable<I4BBulkOperationApiResponse> {
    const update$ = new BehaviorSubject<number>(0);
    const cancel$ = new Subject<void>();
    const chunks: string[][] = chunk(eventsIds, maxLength);
    let currentChunkIndex = 0;
    const chunkLength = chunks.length;
    let concatResponse: I4BBulkOperationApiResponse = {} as I4BBulkOperationApiResponse;

    return update$.pipe(
      takeUntil(cancel$),
      switchMap((i: number) =>
        this.getStreamOfBulkStatusUpdates(eventType, chunks[i], status).pipe(
          map((chuckResponse: I4BBulkOperationApiResponse) => {
            currentChunkIndex++;
            concatResponse = {
              status: EventsService.getComputedStatus(chuckResponse.status, concatResponse.status),
              events: { ...concatResponse.events, ...chuckResponse.events }
            };
            if (currentChunkIndex < chunkLength) {
              update$.next(currentChunkIndex);
            } else {
              cancel$.next();
            }
            return concatResponse;
          })
        )
      )
    );
  }

  getStreamOfBulkStatusUpdates(eventType: string, eventsIds: string[], status: string): Observable<I4BBulkOperationApiResponse> {
    return this.httpClient.post<I4BBulkOperationApiResponse>(
      `${this.environment.api.url}${
        eventType === 'asset-events'
          ? this.environment.api.endpoints.assetEventsBulkUpdateByStatus
          : this.environment.api.endpoints.deviceEventsBulkUpdateByStatus
      }${status}`,
      { eventsIds, comment: null }
    );
  }
}
