import { Feature as BaseFeature, FeatureCollection, LineString, Point } from 'geojson';

export interface IotGeoJsonRouteFeature extends BaseFeature<LineString> {
  options?: { [key: string]: any };
}

export interface IotGeoJsonFeature extends BaseFeature<Point> {
  options?: { [key: string]: any };
}

export interface IotGeoJsonFeatureCollection extends FeatureCollection {
  features: IotGeoJsonFeature[] | IotGeoJsonRouteFeature[];
}
