import { Component, effect, input, OnInit } from '@angular/core';
import { FeatureMasterViewComponent } from '@iot-platform/feature/master-view';
import { auditTrailAdditionalOptions } from '../../settings/audit-trail.provider';

@Component({
  selector: 'iot-platform-audit-trail-logs',
  standalone: true,
  imports: [FeatureMasterViewComponent],
  template: ` <iot-platform-feature-master-view [masterView]="masterView()" /> `
})
export class LogsComponent implements OnInit {
  data = input(null);
  masterView = input<string>();

  constructor() {
    effect(
      () => {
        // Listen for data change
        const data = this.data();
        auditTrailAdditionalOptions.set(data);
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {
    // Should set on component init cause signal effect take some time to be triggered
    auditTrailAdditionalOptions.set(this.data());
  }
}
