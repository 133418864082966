import { BusinessProfile, Organization } from '@iot-platform/models/common';
import { createActionGroup, props } from '@ngrx/store';

export const AuthBusinessProfilesApiActions = createActionGroup({
  source: '[Auth] Business Profiles Api Actions',
  events: {
    loadBusinessProfilesSuccess: props<{ businessProfiles: BusinessProfile[] }>(),
    loadBusinessProfilesFailure: props<{ error: any }>(),
    selectBusinessProfileSuccess: props<{ selectedBusinessProfile: BusinessProfile; withRedirect: boolean }>(),
    selectBusinessProfileFailure: props<{ error: any }>(),
    loadSelectedEntityForSession: props<{ selectedEntityId: string }>(),
    loadSelectedEntityForSessionSuccess: props<{ selectedEntity: Organization }>(),
    loadSelectedEntityForSessionFailure: props<{ error: any }>(),
    loadPerimeterEntitiesSuccess: props<{ entities: Organization[] }>(),
    loadPerimeterEntitiesFailure: props<{ error: any }>()
  }
});
