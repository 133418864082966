import { Component, Inject, OnInit, signal } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Graph, GraphSize, GraphType } from '@iot-platform/models/common';
import { Asset, AssetVariable, Site } from '@iot-platform/models/i4b';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';

@Component({
  selector: 'iot-platform-dashboards-create-graph-dialog',
  templateUrl: './create-graph-dialog.component.html',
  styleUrls: ['./create-graph-dialog.component.scss']
})
export class CreateGraphDialogComponent implements OnInit {
  graphForm: UntypedFormGroup;
  initialFormState: string;
  initialAssetVariablesState = '';
  nameMaxLength = 30;

  eventsSubject$: Subject<AssetVariable[]> = new Subject<AssetVariable[]>();
  GraphTypes = GraphType;
  GraphSizes = GraphSize;
  variablesLoading = signal(true);
  allowMultiSelection = signal(true);

  constructor(
    private readonly translateService: TranslateService,
    public dialogRef: MatDialogRef<CreateGraphDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { graph?: Graph; site: Site; assets: Asset[] }
  ) {}

  get title$(): Observable<string> {
    return this.data.graph
      ? this.translateService.get('GRAPH.CREATE_FORM.EDIT_TITLE', { graphName: this.data.graph.name })
      : this.translateService.get('GRAPH.CREATE_FORM.CREATE_TITLE');
  }

  get actionButtonLabel$(): Observable<string> {
    return of(this.data.graph ? 'IOT_DICTIONARY.SAVE' : 'IOT_DICTIONARY.CREATE');
  }

  get name(): AbstractControl {
    return this.graphForm.get('name');
  }

  get variables(): AbstractControl {
    return this.graphForm.get('variables');
  }

  get description(): AbstractControl {
    return this.graphForm.get('description');
  }

  get typeControl(): AbstractControl {
    return this.graphForm.get('type');
  }

  get sizeControl(): AbstractControl {
    return this.graphForm.get('size');
  }

  get currentFormState(): string {
    return JSON.stringify({ graphForm: this.graphForm.value });
  }

  ngOnInit() {
    this.initForm();
    this.initialFormState = this.currentFormState;
  }

  isFormStateChanged(): boolean {
    return this.initialFormState !== this.currentFormState || this.initialAssetVariablesState !== JSON.stringify(this.variables.value);
  }

  initForm(): void {
    this.graphForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.data.graph?.name ?? '', [Validators.required, Validators.maxLength(this.nameMaxLength)]),
      site: new UntypedFormControl({ value: this.data.site.name, disabled: true }, [Validators.required]),
      variables: new UntypedFormControl({ value: this.data.graph?.variables ?? [], disabled: true }, [Validators.required]),
      description: new UntypedFormControl(this.data.graph?.description ?? '', [Validators.maxLength(100)]),
      type: new UntypedFormControl({ value: this.data.graph?.type ?? GraphType.line_chart, disabled: !!this.data.graph }),
      size: new UntypedFormControl({ value: this.data.graph?.size ?? GraphSize.small, disabled: this.data.graph?.type === GraphType.last_value })
    });

    if (this.data.graph?.asset?.id) {
      this.graphForm.disable();
      this.variables.disable();
      this.sizeControl.enable();
      this.name.enable();
      this.description.enable();
    }

    if (this.data.graph?.type === GraphType.last_value) {
      this.allowMultiSelection.set(false);
    }
  }

  onTypeChange(event: MatRadioChange): void {
    this.variables.reset();

    if (event.value === GraphType.last_value) {
      this.sizeControl.setValue(GraphSize.small);
      this.sizeControl.disable();
      this.allowMultiSelection.set(false);
    } else {
      this.sizeControl.enable();
      this.allowMultiSelection.set(true);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    const newGraph: Graph = {
      ...this.data.graph,
      name: this.name.value.trim(),
      entity: this.data.site.entity,
      description: this.description.value,
      variables: this.variables.value,
      referenceVariableId: this.variables.value[0].id,
      site: this.data.site,
      size: this.sizeControl.value,
      type: this.typeControl.value
    };
    this.dialogRef.close(newGraph);
  }

  removeAssetVariable(): void {
    this.eventsSubject$.next(this.variables.value);
  }

  setAssetVariablesInitialState(state: string) {
    this.initialAssetVariablesState = state;
  }
}
