import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';

import { Filter, FilterDefaultOptions } from '@iot-platform/models/common';
import { TranslateModule } from '@ngx-translate/core';
import { FilterComponent } from '../../models/filter.component';
import { AbstractFilterEngineFieldComponent } from '../abstract-filter-engine-field.component';

@Component({
  standalone: true,
  selector: 'iot-platform-ui-input-field',
  templateUrl: './input-field.component.html',
  imports: [FlexLayoutModule, TranslateModule, ReactiveFormsModule, MatFormFieldModule, MatIcon, MatIconButton, MatInput],
  styleUrls: ['./input-field.component.scss']
})
export class InputFieldComponent extends AbstractFilterEngineFieldComponent<FilterDefaultOptions> implements FilterComponent {
  form: UntypedFormGroup = new UntypedFormGroup({
    text: new UntypedFormControl('', [])
  });

  get text(): AbstractControl {
    return this.form.get('text');
  }

  onInputValidation(): void {
    const data = this.data();
    const filter: Filter = {};
    filter.value = this.text.value.trim();
    filter.label = this.text.value.trim();
    filter.criteriaKey = data.criteriaKey;
    filter.criteriaLabel = data.criteriaLabel;
    this.dispatchFilterEvent(filter);
    this.text.reset();
  }
}
