<mat-card class="dialog-card audit-trail-logs-popup">
  <mat-card-title class="dialog-card-header">
    <mat-toolbar class="dialog-toolbar" color="accent" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="dialog-card-title">
        {{ 'AUDIT_TRAIL.HISTORY_LOG_POPUP.TITLE' | translate: { elementName: data?.elementName } }}
      </h1>
      <button (click)="close()" class="regular-round-button" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card-title>

  <mat-card-content class="dialog-card-content audit-trail-logs-popup__card-content" fxLayout="row"
                    fxLayout.lt-md="column">
    <iot-platform-audit-trail-logs [data]="data" [masterView]="masterView" fxFlex />
  </mat-card-content>

  <mat-card-actions class="dialog-card-actions" fxLayout="row" fxLayoutAlign="end center">
    <button (click)="close()" class="button-regular" color="accent"
            mat-raised-button>{{ 'IOT_DICTIONARY.CLOSE' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
