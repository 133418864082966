import * as Leaflet from 'leaflet';
import { IotGeoJsonFeature } from './iot-geo-json-object.model';
import { IotMapDisplayMode } from './iot-map-display-mode.model';

export enum IotMapActionType {
  MAP_READY = 'MAP_READY',
  MARKER_CLICK = 'MARKER_CLICK',
  MAP_MOVE_END = 'MAP_MOVE_END',
  CHANGE_DISPLAY_MODE = 'CHANGE_DISPLAY_MODE'
}

export interface IotMapEvent {
  type: IotMapActionType;
  popup?: Leaflet.Popup | null;
  map?: Leaflet.Map;
  marker?: Leaflet.Marker;
  feature?: IotGeoJsonFeature;
  displayMode?: IotMapDisplayMode;
}
