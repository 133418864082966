export class DuplicateUtils {
  static duplicateValueString(value: string, list: string[], prefix: string = 'Copy', separator: string = '_', maxLength?: number): string {
    let baseValue = value;
    if (prefix && !value.startsWith(prefix)) {
      baseValue = `${prefix}${separator}${value}`;
    }

    // Normalize the value length, ensuring it doesn't exceed maxLength
    const normalizeValueLength = (str: string, suffix: string) => {
      const totalLength = str.length + suffix.length;
      if (maxLength && totalLength > maxLength) {
        // Truncate the base value if necessary to fit the suffix and maxLength
        str = str.substring(0, maxLength - suffix.length);
      }
      return `${str}${suffix}`;
    };

    // Extract the base name by removing any numeric suffix after the separator
    const baseValueParts = baseValue.split(separator);
    // check for only digits
    let d = [...baseValueParts].pop();
    if (!(/^\d+$/.test(d))) {
      d = null;
    }
    // Get the last part after the last separator
    const v = Math.round(parseInt(d));
    if (!isNaN(v)) {
      baseValueParts.pop();
    }
    const baseName = baseValueParts.join(separator);

    // Find the first missing number suffix in the list for baseName
    const getFirstAvailableNumber = (str: string): number => {
      const usedNumbers: Set<number> = new Set();

      // Loop through the list and collect all used numeric suffixes
      list.forEach((item) => {
        // Check if the item starts with "Copy_value_" and ends with a valid number
        // if (item.startsWith(`${str}${separator}`)) {
        if (item.startsWith(str)) {
          const parts = item.split(separator);
          const numberPart = parts[parts.length - 1];
          const number = parseInt(numberPart, 10);
          if (!isNaN(number)) {
            usedNumbers.add(number);
          }
        }
      });

      // Find the first missing number starting from 1
      let nextAvailableNumber = 1;
      while (usedNumbers.has(nextAvailableNumber)) {
        nextAvailableNumber++;
      }

      return nextAvailableNumber;
    };

    // Check if the base value is already in the list
    if (!list.includes(baseValue)) {
      if (maxLength && baseValue.length > maxLength) {
        return this.duplicateValueString(normalizeValueLength(baseValue, ''), list, prefix, separator, maxLength);
      }
      return baseValue;
    }

    // If baseValue exists in the list, find the first available number suffix
    const firstAvailableNumber = getFirstAvailableNumber(baseName);

    const nextValue = normalizeValueLength(baseName, `${separator}${firstAvailableNumber}`);

    // If the value is still taken, recurse to try the next number
    return this.duplicateValueString(nextValue, list, prefix, separator, maxLength);
  }
}
