import { ProcessCellForExportParams } from '@ag-grid-community/core';

export class UserCellHelpers {
  static processValue(params: ProcessCellForExportParams, formattedValue: string, defaultValue: string): string {
    const { value } = params;
    if (!value && defaultValue) {
      return defaultValue;
    }
    return formattedValue;
  }
}
