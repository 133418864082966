import { Component, computed, DestroyRef, inject, Injector, Input, input, model, OnInit, output, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { get } from 'lodash';
import { FormControlType } from '../forms/form-control-type.model';
import { FormField } from '../forms/form-field.model';
import { FormFieldComponent } from '../forms/form-field/form-field.component';
import { FormControlHintType } from '../forms';

@Component({
  standalone: true,
  imports: [FormFieldComponent],
  selector: 'iot-platform-ui-async-autocomplete',
  template: ` <iot-platform-ui-form-field class="iot-platform-ui-async-autocomplete__select-field" [control]="control" [field]="field()" /> `,
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'mat-mdc-select-bp-overlay-pane' }
    }
  ]
})
export class AsyncAutocompleteComponent implements OnInit {
  showSpinner = input<boolean>(false);
  displaySearchIcon = input<boolean>(true);
  data = model<any[]>([]);
  debounceTime = input<number>(300);
  minLength = input<number>(0);
  displayKey = input<string>('id');
  filterBy = input<string>('id');
  placeholder = input<string>('');
  autocomplete = input<boolean>(true);
  clearOnSelect = input<boolean>(false);
  initialItem = input<any>();
  filterKey = input<string>('');
  hintMessage = input<string>('');
  disabled = input<boolean>(false);
  disableOnlyOptions = input<boolean>(false);
  required = input<boolean>(false);
  errorMessage = input<string>();
  tooltip = input<boolean>(false);
  @Input() displayWrapper!: (item: any) => string;

  search = output<string>();
  reset = output<void>();
  selectionChanged = output<any>();

  searchForm = new FormGroup<{ searchKey: FormControl<string> }>({
    searchKey: new FormControl<string>('')
  });

  filteredData: WritableSignal<any[]> = signal([]);
  field: WritableSignal<Partial<FormField>> = signal({});

  protected readonly injector: Injector = inject(Injector);
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);

  get control() {
    return this.searchForm.get('searchKey');
  }

  ngOnInit() {
    this.field.set({
      fxFlex: signal('100%'),
      type: FormControlType.AUTO_COMPLETE,
      name: signal('searchKey'),
      label: this.placeholder,
      required: this.required,
      disabled: this.disabled,
      items: this.filteredData,
      showSpinner: computed(() => this.showSpinner()),
      minLength: this.minLength,
      displaySearchIcon: this.displaySearchIcon,
      initialItem: this.initialItem,
      autocomplete: this.autocomplete,
      clearOnSelect: this.clearOnSelect,
      debounceTime: this.debounceTime,
      disableOnlyOptions: this.disableOnlyOptions,
      error: {
        enabled: signal(true),
        message: this.errorMessage
      },
      tooltip: {
        enabled: this.tooltip
      },
      onReset: () => {
        this.reset.emit();
        const values = this.filterValue(null);
        this.filteredData.set(values);
      },
      onFocus: () => {
        const searchTerm = this.control?.getRawValue?.();
        const values = this.filterValue(searchTerm);
        this.filteredData.set(values);
      },
      valueChange: (searchTerm) => {
        const values = this.filterValue(searchTerm);
        this.filteredData.set(values);
        if (typeof searchTerm === 'string') {
          this.search.emit(searchTerm);
        }
      },
      selectionChange: ({ option: { value } }) => this.selectionChanged.emit(value),
      hint: signal({ type: signal(FormControlHintType.TEXT), text: this.hintMessage }),
      displayBy: (item: any) => this.getDisplayWrapper(item)
    });
  }

  filterValue(value: any): any[] {
    let filterValue = '';
    const filterKey = this.filterKey();
    const data = this.data();
    if (value !== undefined && value !== null) {
      if (filterKey) {
        filterValue = typeof value === 'string' ? value : get(value, filterKey, '');
      } else {
        filterValue = typeof value === 'string' ? value : JSON.stringify(value);
      }
    }
    if (data) {
      return data.filter((option) => {
        if (filterKey) {
          return `${get(option, filterKey, '')}`?.toLowerCase?.()?.includes?.(`${filterValue}`.toLowerCase());
        } else {
          return JSON.stringify(option)?.toLowerCase()?.includes?.(`${filterValue}`.toLowerCase());
        }
      });
    }
    return [];
  }

  getDisplayWrapper = (item: any) => {
    if (this.displayWrapper) {
      return this.displayWrapper(item);
    }
    const displayKey = this.displayKey();
    return get(item, displayKey, item);
  };
}
