<form [formGroup]="form" class="multiple-inputs-container" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="30px">
  @for (input of inputs(); track $index) {
    <div fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field class="input-field" color="accent">
        <mat-label>{{ input.placeholder | translate }}</mat-label>
        <input (keyup.enter)="onInputValidation(input.criteriaKey)" [formControlName]="input.criteriaKey" matInput />
        <button
          (click)="onInputValidation(input.criteriaKey)"
          [disabled]="disabled() || !form.get(input.criteriaKey).value"
          color="accent"
          mat-icon-button
          matTextSuffix
          type="submit"
        >
          <mat-icon style="font-size: 20px">add_circle</mat-icon>
        </button>
      </mat-form-field>
    </div>
  }
</form>
