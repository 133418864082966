import { NavigationRoutes } from '../../common/navigation-routes.enum';
import { TrackingEventIds as BaseTrackingEventIds } from '../../common/tracking/tracking-event-ids.model';

export const TrackingEventIds = {
  ...BaseTrackingEventIds,
  OPEN_DEVICES_FILTER_ENGINE: 'open_devices_filter_engine',
  // Firmware
  NAVIGATE_TO_FIRMWARES_MASTER_VIEW: NavigationRoutes.FIRMWARES,
  OPEN_FIRMWARE_DETAILS: 'open_firmware_details',
  OPEN_FIRMWARES_FILTER_ENGINE: 'open_firmwares_filter_engine',
  REFRESH_FIRMWARES_DATA: 'refresh_firmwares_data',
  EXPORTS_FIRMWARES_DATA: 'export_firmwares_data',
  APPLY_FIRMWARES_FILTERS: 'apply_firmwares_filters',
  // Sensor
  NAVIGATE_TO_SENSORS_MASTER_VIEW: NavigationRoutes.SENSORS,
  OPEN_SENSOR_DETAILS: 'open_sensor_details',
  OPEN_SENSORS_FILTER_ENGINE: 'open_sensors_filter_engine',
  REFRESH_SENSORS_DATA: 'refresh_sensors_data',
  EXPORTS_SENSORS_DATA: 'export_sensors_data',
  APPLY_SENSORS_FILTERS: 'apply_sensors_filters',
  // Device
  NAVIGATE_TO_DEVICES_MASTER_VIEW: NavigationRoutes.DEVICES,
  OPEN_DEVICE_AUDIT_TRAILS: 'open_device_audit_trails',
  SEND_DEVICE_SHELL_COMMAND: 'send_device_shell_command',
  PUBLISH_DEVICE_GLOBAL_CONFIGURATION: 'publish_device_global_configuration',
  CANCEL_DEVICE_GLOBAL_CONFIGURATION: 'cancel_device_global_configuration',
  OPEN_DEVICE_NAME_EDIT_FORM: 'open_device_name_edit_form',
  OPEN_DEVICE_STARTING_DATE_EDIT_FORM: 'open_device_starting_date_edit_form',
  OPEN_DEVICE_AFFILIATE_EDIT_FORM: 'open_device_affiliate_edit_form',
  OPEN_DEVICE_GENERAL_INFO_EDIT_FORM: 'open_device_general_info_edit_form',
  DEVICE_CONFIGURATION_APPLY_TEMPLATE: 'device_configuration_apply_template',
  DEVICE_CONFIGURATION_COPY_CONFIGURATION: 'device_configuration_copy_configuration',
  SAVE_DEVICE_ROLLING_PERIOD: 'save_device_rolling_period',
  // Device overview
  SEND_DEVICE_OVERVIEW_COMMAND: 'send_device_overview_command',
  OPEN_DEVICE_OVERVIEW_MODBUS_EDIT_FORM: 'open_device_overview_modbus_edit_form',
  UPDATE_DEVICE_OVERVIEW_GENERAL_INFORMATION_CONFIGURATION: 'update_device_overview_general_information_configuration',
  UPDATE_DEVICE_OVERVIEW_MODBUS_CONFIGURATION: 'update_device_overview_modbus_configuration',
  OPEN_DEVICE_OVERVIEW_ALARM_EDIT_FORM: 'open_device_overview_alarm_edit_form',
  OPEN_DEVICE_OVERVIEW_AUTONOMY_EDIT_FORM: 'open_device_overview_autonomy_edit_form',
  UPDATE_DEVICE_OVERVIEW_ALARM_CONFIGURATION: 'update_device_overview_alarm_configuration',
  UPDATE_DEVICE_OVERVIEW_AUTONOMY_CONFIGURATION: 'update_device_overview_autonomy_configuration',
  OPEN_DEVICE_OVERVIEW_ENERGY_MODE_EDIT_FORM: 'open_device_overview__energy_mode_edit_form',
  UPDATE_DEVICE_OVERVIEW_ENERGY_MODE_CONFIGURATION: 'update_device_overview__energy_mode_configuration',
  OPEN_DEVICE_OVERVIEW_COMMUNICATION_EDIT_FORM: 'open_device_overview__communication_edit_form',
  UPDATE_DEVICE_OVERVIEW_COMMUNICATION_CONFIGURATION: 'update_device_overview__communication_configuration',
  // Device variable
  DELETE_DEVICE_VARIABLE: 'delete_device_variable',
  DUPLICATE_DEVICE_VARIABLE: 'duplicate_device_variable',
  BULK_DELETE_DEVICE_VARIABLES: 'bulk_delete_device_variables',
  CREATE_DEVICE_VARIABLE: 'create_device_variable',
  EDIT_DEVICE_VARIABLE: 'edit_device_variable',
  CANCEL_DEVICE_VARIABLE_PENDING_CONFIGURATION: 'cancel_device_variable_pending_configuration',
  CANCEL_DEVICE_VARIABLE_DELETE_CONFIGURATION: 'cancel_device_variable_delete_configuration',
  OPEN_DEVICE_VARIABLE_DETAILS: 'open_device_variable_details',
  OPEN_DEVICE_VARIABLE_CHART: 'open_device_variable_chart',
  OPEN_DEVICE_VARIABLE_CREATE_FORM: 'open_device_variable_create_form',
  OPEN_DEVICE_VARIABLE_EDIT_FORM: 'open_device_variable_edit_form',
  // Device tank
  OPEN_DEVICE_TANK_CREATE_FORM: 'open_device_tank_create_form',
  CREATE_DEVICE_TANK: 'create_device_tank',
  OPEN_DEVICE_TANK_EDIT_FORM: 'open_device_tank_edit_form',
  UPDATE_DEVICE_TANK: 'update_device_tank',
  DELETE_DEVICE_TANK: 'delete_device_tank',
  DUPLICATE_DEVICE_TANK: 'duplicate_device_tank',
  CANCEL_DEVICE_TANK_PENDING_CONFIGURATION: 'cancel_device_tank_pending_configuration',
  CANCEL_DEVICE_TANK_DELETE_CONFIGURATION: 'cancel_device_tank_delete_configuration',
  // Device modbus request table
  OPEN_DEVICE_MODBUS_REQUEST_TABLE_CREATE_FORM: 'open_device_modbus_request_table_create_form',
  CREATE_DEVICE_MODBUS_REQUEST_TABLE: 'create_device_modbus_request_table',
  OPEN_DEVICE_MODBUS_REQUEST_TABLE_EDIT_FORM: 'open_device_modbus_request_table_edit_form',
  UPDATE_DEVICE_MODBUS_REQUEST_TABLE: 'update_device_modbus_request_table',
  DELETE_DEVICE_MODBUS_REQUEST_TABLE: 'delete_device_modbus_request_table',
  DUPLICATE_DEVICE_MODBUS_REQUEST_TABLE: 'duplicate_device_modbus_request_table',
  CANCEL_DEVICE_MODBUS_REQUEST_TABLE_PENDING_CONFIGURATION: 'cancel_device_modbus_request_table_pending_configuration',
  CANCEL_DEVICE_MODBUS_REQUEST_TABLE_DELETE_CONFIGURATION: 'cancel_device_modbus_request_table_delete_configuration',
  // Device template
  OPEN_DEVICE_TEMPLATE_STARTING_DATE_EDIT_FORM: 'open_device_template_starting_date_edit_form',
  OPEN_DEVICE_TEMPLATE_NAME_EDIT_FORM: 'open_device_template_name_edit_form',
  OPEN_DEVICE_TEMPLATE_IMPORT_FROM_DEVICE_FORM: 'open_device_template_import_from_device_form',
  OPEN_DEVICE_TEMPLATE_IMPORT_FROM_TEMPLATE_FORM: 'open_device_template_import_from_template_form',
  SAVE_DEVICE_TEMPLATE_ROLLING_PERIOD: 'save_device_template_rolling_period',
  NAVIGATE_TO_DEVICE_TEMPLATES_MASTER_VIEW: NavigationRoutes.DEVICE_TEMPLATES,
  OPEN_DEVICE_TEMPLATE_DETAILS: 'open_device_template_details',
  OPEN_DEVICE_TEMPLATES_FILTER_ENGINE: 'open_device_templates_filter_engine',
  REFRESH_DEVICE_TEMPLATES_DATA: 'refresh_device_templates_data',
  EXPORTS_DEVICE_TEMPLATES_DATA: 'export_device_templates_data',
  APPLY_DEVICE_TEMPLATES_FILTERS: 'apply_device_templates_filters',
  DELETE_DEVICE_TEMPLATE: 'delete_device_template',
  OPEN_DEVICE_TEMPLATE_CREATE_FORM: 'open_device_template_create_form',
  CREATE_DEVICE_TEMPLATE: 'create_device_template',
  DUPLICATE_DEVICE_TEMPLATE: 'duplicate_device_template',
  OPEN_DEVICE_TEMPLATE_EDIT_FORM: 'open_device_template_edit_form',
  UPDATE_DEVICE_TEMPLATE: 'update_device_template',
  DEVICE_TEMPLATE_DETAILS_TAB_CHANGE: 'device_template_details_tab_change',
  // Device template overview
  UPDATE_DEVICE_TEMPLATE_OVERVIEW_GENERAL_INFORMATION_CONFIGURATION: 'update_device_template_overview_general_information_configuration',
  OPEN_DEVICE_TEMPLATE_OVERVIEW_MODBUS_EDIT_FORM: 'open_device_template_overview_modbus_edit_form',
  UPDATE_DEVICE_TEMPLATE_OVERVIEW_MODBUS_CONFIGURATION: 'update_device_template_overview_modbus_configuration',
  OPEN_DEVICE_TEMPLATE_OVERVIEW_ALARM_EDIT_FORM: 'open_device_template_overview_alarm_edit_form',
  OPEN_DEVICE_TEMPLATE_OVERVIEW_AUTONOMY_EDIT_FORM: 'open_device_template_overview_autonomy_edit_form',
  UPDATE_DEVICE_TEMPLATE_OVERVIEW_ALARM_CONFIGURATION: 'update_device_template_overview_alarm_configuration',
  UPDATE_DEVICE_TEMPLATE_OVERVIEW_AUTONOMY_CONFIGURATION: 'update_device_template_overview_autonomy_configuration',
  OPEN_DEVICE_TEMPLATE_OVERVIEW_ENERGY_MODE_EDIT_FORM: 'open_device_template_overview__energy_mode_edit_form',
  UPDATE_DEVICE_TEMPLATE_OVERVIEW_ENERGY_MODE_CONFIGURATION: 'update_device_template_overview__energy_mode_configuration',
  OPEN_DEVICE_TEMPLATE_OVERVIEW_COMMUNICATION_EDIT_FORM: 'open_device_template_overview__communication_edit_form',
  UPDATE_DEVICE_TEMPLATE_OVERVIEW_COMMUNICATION_CONFIGURATION: 'update_device_template_overview__communication_configuration',
  REFRESH_DEVICE_TEMPLATE_OVERVIEW_DATA: 'refresh_device_template_details_data',
  NAVIGATE_TO_DEVICE_TEMPLATE_OVERVIEW_PAGE: 'navigate_to_device_template_overview_page',
  // Device template variable
  DELETE_DEVICE_TEMPLATE_VARIABLE: 'delete_device_template_variable',
  DUPLICATE_DEVICE_TEMPLATE_VARIABLE: 'duplicate_device_template_variable',
  OPEN_DEVICE_TEMPLATE_VARIABLE_DETAILS: 'open_device_template_variable_details',
  OPEN_DEVICE_TEMPLATE_VARIABLE_CREATE_FORM: 'open_device_template_variable_create_form',
  OPEN_DEVICE_TEMPLATE_VARIABLE_EDIT_FORM: 'open_device_template_variable_edit_form',
  CREATE_DEVICE_TEMPLATE_VARIABLE: 'create_device_template_variable',
  EDIT_DEVICE_TEMPLATE_VARIABLE: 'edit_device_template_variable',
  // Device template tank
  OPEN_DEVICE_TEMPLATE_TANK_CREATE_FORM: 'open_device_template_tank_create_form',
  CREATE_DEVICE_TEMPLATE_TANK: 'create_device_template_tank',
  OPEN_DEVICE_TEMPLATE_TANK_EDIT_FORM: 'open_device_template_tank_edit_form',
  UPDATE_DEVICE_TEMPLATE_TANK: 'update_device_template_tank',
  DELETE_DEVICE_TEMPLATE_TANK: 'delete_device_template_tank',
  DUPLICATE_DEVICE_TEMPLATE_TANK: 'duplicate_device_template_tank',
  // Device template modbus request table
  OPEN_DEVICE_TEMPLATE_MODBUS_REQUEST_TABLE_CREATE_FORM: 'open_device_template_modbus_request_table_create_form',
  CREATE_DEVICE_TEMPLATE_MODBUS_REQUEST_TABLE: 'create_device_template_modbus_request_table',
  OPEN_DEVICE_TEMPLATE_MODBUS_REQUEST_TABLE_EDIT_FORM: 'open_device_template_modbus_request_table_edit_form',
  UPDATE_DEVICE_TEMPLATE_MODBUS_REQUEST_TABLE: 'update_device_template_modbus_request_table',
  DELETE_DEVICE_TEMPLATE_MODBUS_REQUEST_TABLE: 'delete_device_template_modbus_request_table',
  DUPLICATE_DEVICE_TEMPLATE_MODBUS_REQUEST_TABLE: 'duplicate_device_template_modbus_request_table'
};
