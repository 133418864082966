<div class="ag-filter">
  <div class="ag-filter-wrapper ag-focus-managed">
    <div class="ag-filter-body-wrapper ag-simple-filter-body-wrapper">
      <iot-platform-ui-form-group [fields]="fields()" [group]="form" style="max-width: 12rem;" />
    </div>
    <div class="ag-filter-apply-panel">
      <button (click)="reset()" class="ag-standard-button ag-filter-apply-panel-button" ref="resetFilterButton"
              type="button">
        {{ 'AG_GRID.resetFilter' | translate }}
      </button>
    </div>
  </div>
</div>
