import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthorizationConcept, AuthorizationService, AuthorizationType } from '@iot-platform/auth';
import { CustomEncoder, ENVIRONMENT, LocalStorageKeys, LocalStorageService } from '@iot-platform/core';
import { CommonApiListResponse, Environment, PlatformRequest, PlatformResponse, TagCategory } from '@iot-platform/models/common';
import { Asset, AssetVariable, Concept, Device, DeviceEvent, DeviceVariable, Log, Site } from '@iot-platform/models/i4b';
import * as moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceEventsService extends EventsService {
  protected readonly environment: Environment = inject(ENVIRONMENT);
  protected readonly httpClient: HttpClient = inject(HttpClient);
  private readonly storage: LocalStorageService = inject(LocalStorageService);
  private readonly authorizationService: AuthorizationService = inject(AuthorizationService);

  getDeviceEvents(request: PlatformRequest): Observable<PlatformResponse> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });

    params = params.set('limit', request.limit?.toString(10) ?? '100');
    params = params.set('page', request.page?.toString(10) ?? '0');

    if (request.filters) {
      request.filters.forEach((filter) => {
        params = params.append(filter.criteriaKey, filter.value);
      });
    }

    return this.httpClient.get<DeviceEvent[]>(`${this.environment.api.url + this.environment.api.endpoints.deviceEvents}`, { params }).pipe(
      map((data: any) => ({
        data: data.content,
        currentPage: data.page.curPage,
        hasMore: data.page.hasMore,
        limit: data.page.limit,
        maxPage: data.page.maxPage,
        total: data.page.total
      }))
    );
  }

  getDeviceEventById(eventId: string): Observable<DeviceEvent> {
    return this.httpClient.get<DeviceEvent>(`${this.environment.api.url}${this.environment.api.endpoints.deviceEvents}/${eventId}`);
  }

  getSiteById(siteId: string): Observable<Site> {
    return this.httpClient.get<Site>(this.environment.api.url + this.environment.api.endpoints.sites + '/' + siteId);
  }

  getDeviceById(deviceId: string): Observable<Device> {
    return this.httpClient.get<Device>(this.environment.api.url + this.environment.api.endpoints.devices + '/' + deviceId);
  }

  getDeviceVariableById(deviceVariableId: string): Observable<DeviceVariable | Error> {
    if (!deviceVariableId) {
      return of(null);
    }
    return this.httpClient.get<DeviceVariable>(this.environment.api.url + this.environment.api.endpoints.deviceVariables + '/' + deviceVariableId);
  }

  getAssetById(assetId: string): Observable<Asset | Error | undefined> {
    return assetId ? this.httpClient.get<Asset>(this.environment.api.url + this.environment.api.endpoints.assets + '/' + assetId) : of(undefined);
  }

  getAssetVariableById(assetVariableId: string): Observable<AssetVariable | Error | undefined> {
    return assetVariableId
      ? this.httpClient.get<AssetVariable>(this.environment.api.url + this.environment.api.endpoints.assetVariables + '/' + assetVariableId)
      : of(undefined);
  }

  loadComments(deviceEvent: DeviceEvent): Observable<Log[]> {
    let params: HttpParams = new HttpParams({ encoder: new CustomEncoder() });
    params = params.set('startDate', moment(deviceEvent.receptionTime).subtract(1, 'months').toISOString());

    const canReadDevice = this.authorizationService.applyAuthorization(AuthorizationConcept.DEVICE, AuthorizationType.READ);
    const canReadSite = this.authorizationService.applyAuthorization(AuthorizationConcept.SITE, AuthorizationType.READ);

    return forkJoin([
      this.httpClient.get<CommonApiListResponse<Log>>(
        `${this.environment.api.url}${this.environment.api.endpoints.deviceEvents}/${deviceEvent.id}${this.environment.api.endpoints.logs}`
      ),
      canReadDevice
        ? this.httpClient.get<Log[]>(
            `${this.environment.api.url}${this.environment.api.endpoints.devices}/${deviceEvent.context?.device?.id}${this.environment.api.endpoints.logs}`,
            { params }
          )
        : of([]),
      canReadSite
        ? this.httpClient.get<Log[]>(
            `${this.environment.api.url}${this.environment.api.endpoints.sites}/${deviceEvent.context?.site?.id}${this.environment.api.endpoints.logs}`,
            { params }
          )
        : of([])
    ]).pipe(
      map(([eventLogs, deviceLogs, siteLogs]) => [
        ...eventLogs.content.map((log: Log) => ({ ...log, concept: Concept.EVENT, icon: 'device-event' })),
        ...deviceLogs.map((log: Log) => ({ ...log, concept: Concept.DEVICE, icon: 'device' })),
        ...siteLogs.map((log: Log) => ({ ...log, concept: Concept.SITE, icon: 'site' }))
      ])
    );
  }

  addComment(deviceEventId: string, comment: string): Observable<Log> {
    return this.httpClient
      .put<Log>(`${this.environment.api.url}${this.environment.api.endpoints.deviceEvents}/${deviceEventId}/comments`, { comment })
      .pipe(map((log: Log) => ({ ...log, concept: Concept.EVENT, icon: 'device-event' })));
  }

  editComment(deviceEventId: string, comment: Log): Observable<Log> {
    return this.httpClient
      .put<Log>(
        `${this.environment.api.url}${this.environment.api.endpoints.deviceEvents}/${deviceEventId}${this.environment.api.endpoints.logs}/${comment.id}`,
        {
          comment: comment.comment
        }
      )
      .pipe(map((log: Log) => ({ ...log, concept: Concept.EVENT, icon: 'device-event' })));
  }

  deleteComment(deviceEventId: string, commentId: string): Observable<string> {
    return this.httpClient
      .delete<void>(
        `${this.environment.api.url}${this.environment.api.endpoints.deviceEvents}/${deviceEventId}${this.environment.api.endpoints.logs}/${commentId}`
      )
      .pipe(map(() => commentId));
  }

  getTagsByDeviceEventId(deviceEventId: string): Observable<TagCategory[]> {
    return this.httpClient
      .get<TagCategory[]>(this.environment.api.url + this.environment.api.endpoints.deviceEvents + `/${deviceEventId}/tags`)
      .pipe(map((data: any) => data.content));
  }

  putStatus(status: { deviceEventId: string; value: string }): Observable<DeviceEvent> {
    return this.httpClient.put<DeviceEvent>(
      `${this.environment.api.url}${this.environment.api.endpoints.deviceEvents}/${status.deviceEventId}/${status.value}`,
      {
        comment: null
      }
    );
  }

  // TODO remove and use EventService.bulkUpdateStatusByEventType
  bulkUpdateStatus(deviceEventIds: string[], status: string): Observable<DeviceEvent>[] {
    return deviceEventIds.map((id) => this.putStatus({ deviceEventId: id, value: status }));
  }

  saveTableState(tableState: { selected: DeviceEvent; checked: DeviceEvent[] }): Observable<{ selected: DeviceEvent; checked: DeviceEvent[] }> {
    this.storage.set(LocalStorageKeys.STORAGE_MV_DEVICE_EVENTS_TABLE_STATE_KEY, JSON.stringify(tableState));
    return of(tableState);
  }
}
