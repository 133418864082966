import { HistoryLog, HistoryLogDetail } from '@iot-platform/models/audit-trail';
import { CommonApiRequest, CommonApiResponse, Pagination } from '@iot-platform/models/common';
import { Observable } from 'rxjs';

export abstract class AbstractAuditTrailService<T extends CommonApiRequest> {
  abstract getAll(params?: T): Observable<CommonApiResponse<HistoryLog, Pagination>>;

  abstract getById(params: { id: string } & T): Observable<HistoryLogDetail>;

  abstract exportLogDetails(logDetails?: HistoryLogDetail): void;
}
