import { computed, EnvironmentProviders, InjectionToken, makeEnvironmentProviders, ModuleWithProviders, NgModule, signal, WritableSignal } from '@angular/core';
import { AuthFacade } from '@iot-platform/auth';
import { provideFeatureMasterViewSettingsFactory } from '@iot-platform/feature/master-view';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuditTrailEffects } from '../+state/effects/audit-trail.effects';
import { AuditTrailFacade } from '../+state/facades/audit-trail.facade';
import { provideAuditTrailState } from '../+state/reducers';
import { auditTrailFeature } from '../+state/reducers/audit-trail.reducer';
import { LogDetailsComponent } from '../components/log-details/log-details.component';
import { AUDIT_TRAIL_MASTER_VIEW_NAME } from '../grids/audit-trail-grid-definition';
import { AuditTrailSettings } from '../models/audit-trails-settings';
import { AbstractAuditTrailService } from '../services/abstract-audit-trail.service';
import { AuditTrailMasterViewService } from '../services/audit-trail-master-view.service';

export const auditTrailAdditionalOptions: WritableSignal<{
  concept: string;
  elementId: string;
  elementName: string;
} | null> = signal(null);

export const AUDIT_TRAIL_SETTINGS = new InjectionToken<AuditTrailSettings>('AUDIT_TRAIL_SETTINGS');

const getAuditTrailProviders = (settings: AuditTrailSettings) => [
  { provide: AUDIT_TRAIL_SETTINGS, useValue: settings },
  { provide: AbstractAuditTrailService, useClass: settings.auditTrailService },
  provideFeatureMasterViewSettingsFactory({
    authFacade: AuthFacade,
    masterViewFacade: AuditTrailFacade,
    masterViewService: AuditTrailMasterViewService,
    settingsFactory: ({ masterViewFacade }) => {
      const drawerDetailsOpened = signal(false);
      return {
        masterViewName: signal(AUDIT_TRAIL_MASTER_VIEW_NAME),
        additionalOptions: auditTrailAdditionalOptions,
        grid: {
          staticDefinitionType: signal(true),
          hidePaginator: signal(true)
        },
        toolbar: {
          enabled: signal(false)
        },
        filterEngine: {
          enabled: signal(false)
        },
        drawerDetails: {
          enabled: signal(true),
          position: signal('end'),
          mode: signal('side'),
          opened: drawerDetailsOpened,
          openedOnDestroy: signal(false),
          hasBackdrop: signal(true),
          closeOnBackdropClick: signal(true),
          componentRef: computed(() => ({
            ref: LogDetailsComponent,
            inputs: {
              loading: masterViewFacade.loading(),
              entity: (masterViewFacade as AuditTrailFacade).currentLogDetails(),
              toggleDrawerDetails: (opened: boolean) => drawerDetailsOpened.set(opened)
            }
          }))
        }
      };
    }
  })
];

// Used for lazy loading in a specific module
export function provideAuditTrailSettings(settings: AuditTrailSettings): EnvironmentProviders {
  return makeEnvironmentProviders([provideAuditTrailState(), ...getAuditTrailProviders(settings)]);
}

// Used to be imported in app module
// Cause the provide state throws an error, so we should use StoreModule.forFeature instead
@NgModule({
  imports: [StoreModule.forFeature(auditTrailFeature.name, auditTrailFeature.reducer), EffectsModule.forFeature([AuditTrailEffects])]
})
export class AuditTrailModule {
  static forRoot(settings: AuditTrailSettings): ModuleWithProviders<AuditTrailModule> {
    return {
      ngModule: AuditTrailModule,
      providers: [getAuditTrailProviders(settings)]
    };
  }
}
