import { BusinessProfile, Entity, UserAccount } from '@iot-platform/models/common';
import { createFeature, createReducer, on } from '@ngrx/store';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AuthApiActions, AuthBusinessProfilesApiActions, AuthPageActions } from '../actions';

export interface AuthApiFeatureState {
  loggedIn: boolean;
  loggedOut: boolean;
  tokenExpired: boolean;
  businessProfileInitialized: boolean;
  idToken: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  currentUser: UserAccount | null;
  cognitoUser: CognitoUser | null;
  userId: string | null;
  firstLogIn: boolean;
  businessProfiles: BusinessProfile[] | null;
  selectedBusinessProfile: BusinessProfile | null;
  currentEntity: Entity | null;
  currentEntityId: string | null;
  perimeterEntities: Entity[] | null;
  privileges: any;
  preferences: any;
  isUserAdmin: boolean;
  initialStateFromStore: any;
  loggedInWithSSO: boolean;
  ssoTokenExpiresAt: string;
}

export const initialState: AuthApiFeatureState = {
  loggedIn: false,
  businessProfileInitialized: false,
  loggedOut: false,
  tokenExpired: true,
  idToken: null,
  accessToken: null,
  refreshToken: null,
  currentUser: null,
  cognitoUser: null,
  userId: null,
  firstLogIn: false,
  businessProfiles: null,
  selectedBusinessProfile: null,
  currentEntity: null,
  currentEntityId: null,
  perimeterEntities: null,
  privileges: null,
  preferences: null,
  isUserAdmin: false,
  initialStateFromStore: {},
  loggedInWithSSO: false,
  ssoTokenExpiresAt: ''
};

export const authApiFeature = createFeature({
  name: 'authApi',
  reducer: createReducer(
    initialState,
    on(AuthPageActions.signIn, (): AuthApiFeatureState => initialState),

    // This action will be dispatched in auth module constructor
    on(
      AuthApiActions.setCachedInitialState,
      (state: AuthApiFeatureState, { initialState: initialStateFromStore }): AuthApiFeatureState => ({
        ...state,
        initialStateFromStore
      })
    ),
    on(
      AuthApiActions.retrieveSession,
      (state: AuthApiFeatureState): AuthApiFeatureState => ({
        ...state,
        ...state.initialStateFromStore
      })
    ),
    on(
      AuthApiActions.signOutSuccess,
      (state: AuthApiFeatureState): AuthApiFeatureState => ({
        ...state,
        loggedOut: true
      })
    ),
    on(
      AuthApiActions.signInWithSSOSuccess,
      AuthApiActions.retrieveSsoSessionSuccess,
      (state: AuthApiFeatureState, { idToken, accessToken, refreshToken }): AuthApiFeatureState => ({
        ...state,
        loggedIn: true,
        loggedInWithSSO: true,
        accessToken,
        idToken,
        refreshToken
      })
    ),
    on(
      AuthApiActions.refreshSsoTokensSuccess,
      (state: AuthApiFeatureState, { idToken, accessToken }): AuthApiFeatureState => ({
        ...state,
        accessToken,
        idToken
      })
    ),
    on(AuthApiActions.signInSuccess, (state: AuthApiFeatureState, { cognitoUser }) => ({
      ...state,
      cognitoUser,
      loggedIn: true,
      tokenExpired: false,
      idToken: cognitoUser ? cognitoUser.getSignInUserSession().getIdToken().getJwtToken() : null,
      refreshToken: cognitoUser ? cognitoUser.getSignInUserSession().getRefreshToken().getToken() : null,
      userId: cognitoUser ? cognitoUser.getUsername() : null
    })),
    on(AuthApiActions.retrieveSessionSuccess, (state: AuthApiFeatureState, { cognitoUser }) => ({
      ...state,
      cognitoUser,
      loggedIn: true,
      tokenExpired: false,
      idToken: cognitoUser ? cognitoUser.getSignInUserSession().getIdToken().getJwtToken() : null,
      refreshToken: cognitoUser ? cognitoUser.getSignInUserSession().getRefreshToken().getToken() : null,
      userId: cognitoUser ? cognitoUser.getUsername() : null
    })),
    on(AuthApiActions.retrieveSessionFailure, (state: AuthApiFeatureState) => {
      localStorage.clear();
      const s = {
        ...state,
        loggedIn: false,
        loggedOut: true,
        tokenExpired: true,
        idToken: null,
        accessToken: null,
        refreshToken: null,
        currentUser: null,
        cognitoUser: null,
        firstLogIn: false,
        businessProfiles: null,
        selectedBusinessProfile: null,
        currentEntity: null,
        perimeterEntities: null,
        privileges: null,
        preferences: null,
        isUserAdmin: false
      };

      return s;
    }),
    on(AuthApiActions.loadAccountSuccess, (state: AuthApiFeatureState, { account }) => ({
      ...state,
      currentUser: account,
      userId: account.id,
      businessProfiles: account.businessProfiles,
      preferences: account.preferences
    })),
    on(AuthApiActions.setBusinessProfiles, (state: AuthApiFeatureState, { businessProfiles }) => ({
      ...state,
      businessProfiles: [...businessProfiles],
      businessProfileInitialized: true
    })),
    on(AuthApiActions.loadPrivilegesSuccess, (state: AuthApiFeatureState, { privileges }) => ({
      ...state,
      privileges
    })),
    on(AuthPageActions.loadPrivileges, (state: AuthApiFeatureState) => ({
      ...state,
      privileges: null
    })),
    on(AuthApiActions.signInFailure, (state: AuthApiFeatureState, { error }) => ({ ...state, error })),
    on(AuthPageActions.signOut, () => ({ ...initialState })),
    on(AuthApiActions.signOutFailure, (state: AuthApiFeatureState) => ({ ...state })),
    on(AuthApiActions.changePasswordSuccess, (state: AuthApiFeatureState) => ({ ...state, loggedIn: false })),
    on(AuthPageActions.requireNewPassword, (state: AuthApiFeatureState) => ({ ...state, loggedIn: true })),
    on(AuthApiActions.changePasswordFailure, (state: AuthApiFeatureState) => ({ ...state })),
    on(AuthApiActions.forgotPasswordSuccess, (state: AuthApiFeatureState) => ({ ...state })),
    on(AuthApiActions.forgotPasswordFailure, (state: AuthApiFeatureState) => ({ ...state })),
    on(AuthApiActions.checkIfUserIsAdminSuccess, (state: AuthApiFeatureState, { isAdminUser }) => ({
      ...state,
      isUserAdmin: isAdminUser
    })),
    on(AuthBusinessProfilesApiActions.selectBusinessProfileSuccess, (state: AuthApiFeatureState, { selectedBusinessProfile }) => ({
      ...state,
      selectedBusinessProfile
    })),
    on(AuthApiActions.refreshTokenSuccess, (state: AuthApiFeatureState, { refreshed }) => ({
      ...state,
      idToken: refreshed.getSignInUserSession().getIdToken().getJwtToken(),
      refreshToken: refreshed.getSignInUserSession().getRefreshToken().getToken()
    })),
    on(AuthApiActions.refreshSsoTokensSuccess, AuthApiActions.signInWithSSOSuccess, (state: AuthApiFeatureState, { expiresIn }) => ({
      ...state,
      ssoTokenExpiresAt: new Date(Date.now() + parseInt(`${expiresIn}`) * 1000).toISOString()
    })),
    on(AuthBusinessProfilesApiActions.loadSelectedEntityForSessionSuccess, (state: AuthApiFeatureState, { selectedEntity }) => ({
      ...state,
      currentEntity: selectedEntity,
      currentEntityId: selectedEntity.id
    })),
    on(AuthBusinessProfilesApiActions.loadPerimeterEntitiesSuccess, (state: AuthApiFeatureState, { entities }) => ({
      ...state,
      perimeterEntities: entities
    }))
  )
});
